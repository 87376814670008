import React from "react";
import clsx from "clsx";
import { Box, Grid, withStyles } from "@material-ui/core";

const Modal = ({ open, children, classes, className, ...props }) => {
	return open ? (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			className={clsx(classes.root, className)}
		>
			<Grid item>{children}</Grid>
		</Grid>
	) : (
		""
	);
};

Modal.propTypes = {};

export default withStyles(theme => ({
	root: {
		width: "100%",
		height: "100%",
		position: "absolute",
		left: 0,
		top: 0,
		backgroundColor: theme.palette.background.paperOverlay,
		"& .MuiGrid-item": {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2),
			borderRadius: theme.shape.borderRadius
		}
	}
}))(Modal);

export { default as DateSelector } from "./date-selector";
export { default as JumpLinks } from "./jump-links";
export { default as JumpTarget } from "./jump-target";
export { default as JurisdictionSelector } from "./jurisdiction-selector";
export { default as Modal } from "./modal";
export { default as Toggle } from "./toggle";
export { default as Tooltip } from "./tooltip";
export { default as TooltipWrapper } from "./tooltip-wrapper";

// source: https://www.census.gov/library/reference/code-lists/ansi/ansi-codes-for-states.html
export const TERRITORIES = [
	"60",
	"64",
	"66",
	"68",
	"69",
	"70",
	"72",
	"74",
	"78",
	"81",
	"84",
	"86",
	"67",
	"89",
	"71",
	"76",
	"95",
	"79"
];
export const DISTRICTS = ["11"];

export const getLevel = fips => {
	if (TERRITORIES.some(substr => String(fips).startsWith(substr))) {
		if (String(fips).length > 2) {
			return "municipality";
		}
		return "territory";
	} else if (DISTRICTS.some(substr => String(fips).startsWith(substr))) {
		return "district";
	} else {
		if (String(fips).length > 2) {
			return "county";
		}
		return "state";
	}
};

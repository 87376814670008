import { withStyles } from "@material-ui/core";
import MobileNavigation from "gatsby-theme-hypersite/src/header/mobile-navigation";

export default withStyles(theme => ({
	root: {
		"& .MuiSvgIcon-root": {
			fill: theme.palette.blue.dark
		},
		"& .MuiButton-text .MuiButton-label::after": {
			display: "none"
		}
	},
	button: {},
	drawer: {
		"& .MuiDrawer-paper": {
			background: theme.palette.background.paper
		},
		// "& .HypNavigation-link": {
		// 	padding: theme.spacing(2, 3),
		// 	fontSize: theme.typography.pxToRem(16),
		// 	color: "#B6AFA4",
		// 	"&[class*='linkActive']": {
		// 		color: "#fff",
		// 	},
		// },
		"& .HypNavigation-link": {
			// height: "100%",
			color: theme.palette.blue.dark,
			// letterSpacing: 1,
			textDecoration: "none",
			...theme.typography.body.semibold,
			transition: theme.transitions.create(["background-color"])
			// "&:hover, &:focus-within": {
			//   background: null,
			//   backgroundColor: "",
			//   color: theme.palette.blue.dark,
			//   textDecoration: "underline",
			//   textUnderlineOffset: "6px",
			// },
		}
	},
	close: {
		color: theme.palette.blue.dark
	}
}))(MobileNavigation);

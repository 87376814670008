import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	withStyles
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";

const JurisdictionSelector = ({
	classes,
	className,
	value,
	options,
	label,
	placeholder,
	handleChange,
	data,
	center,
	...props
}) => {
	const onChange = (e, val) => {
		handleChange(val);
	};

	return (
		<Box className={clsx(classes.root, className)}>
			<Box>
				<Typography>{label}</Typography>
				&nbsp;
				<FormControl
				// className={classes.root}
				>
					<Autocomplete
						options={options.map(o => o)}
						renderInput={params => (
							<TextField {...params} placeholder={placeholder} />
						)}
						onChange={onChange}
					/>
				</FormControl>
			</Box>
		</Box>
	);
};

JurisdictionSelector.propTypes = {};

export default withStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		"& > .MuiBox-root": {
			display: "flex",
			alignItems: "center",
			margin: "auto",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.spacing(3),
				flexDirection: "column"
			}
		},
		"& .MuiFormControl-root": {
			width: theme.spacing(32),
			marginLeft: theme.spacing(0.5),
			// marginLeft: "auto",
			// marginRight: "auto",
			position: "relative",
			// top: theme.typography.pxToRem(-8),
			// marginBottom: theme.spacing(8),
			display: "flex"
		},
		"& .MuiInputBase-root": {
			width: "100%"
		}
	}
}))(JurisdictionSelector);

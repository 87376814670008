import React from "react";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { TooltipWrapper } from "../common";
import { COLORS, FONTS } from "../../theme";

import { formatDate, formatNumber } from "../../utils";

const Stats = ({ classes, className, data, national, schema, ...props }) => {
	const latestDate = formatDate(data.filings_latest_date, {
		year: "numeric",
		month: "long"
	});

	const disposedCasesCount = parseInt(data.disposed_case_count).toLocaleString()

	const defaultSchema = [
		{
			key: "filings_count",
			label: "Filings since March 16, 2020"
		},
		{
			key: "filings_latest",
			label: `Filings in ${latestDate}`
		},
		{
			key: "represent_landlords",
			label: `of landlords have representation in court in 2022 (of ${disposedCasesCount} cases disposed)`,
			percent: true
		},
		{
			key: "represent_tenants",
			label: `of tenants have representation in court in 2022 (of ${disposedCasesCount} cases disposed)`,
			percent: true,
			popup: "Parties represented in at least one hearing. For more information, see the FAQ section for more information."
		},
		{
			key: "cases_judgment",
			label: `cases went to judgment in ${latestDate}`
		},
		{
			key: "cases_default",
			label: `cases went to default in ${latestDate}`
		}
	];

	const getDiff = stat => {
		// this value returns the national value for a given statistic, if it exists
		const natVal = Number(national[stat.key]);
		if (!natVal) return "";
		const output_value = stat.percent ? `${natVal}%` : `${natVal}`;
		return output_value; // just return national value and not do any JS magic on this
	};

	return (
		<div className={clsx(classes.root, className)} {...props}>
			<Grid container spacing={2} className={classes.statGrid}>
				{(schema || defaultSchema).map((stat, i) =>
					data[stat.key] ? (
						<Grid item xs={12} sm={6} key={i}>
							<div>
								<Typography component="span" variant="h4">
									{data[stat.key] ? formatNumber(data[stat.key], 1) : ""}
									{stat.percent ? "%" : ""}
									{ stat.popup ?
							<TooltipWrapper
								icon={true}
								content={stat.popup}
								light={true}
							></TooltipWrapper> : <></>
							}
								</Typography>
								{national && national[stat.key] ? (
									<Box className={classes.statDiff}>
										{i === 0 ? (
											<Box className={classes.statNatNote}>
												<Typography component="span">
													<img
														src={`/images/arrow-${
															schema ? "dark" : "light"
														}.svg`}
														alt=""
													/>
													nationally
												</Typography>
											</Box>
										) : (
											""
										)}
										<Typography component="sup">({getDiff(stat)})</Typography>
									</Box>
									
								) : (
									""
								)}
							</div>

								{stat.label}

							
						</Grid>
					) : (
						""
					)
				)}
			</Grid>
		</div>
	);
};

Stats.propTypes = {};

export default withStyles(theme => ({
	root: {
		paddingTop: theme.spacing(4)
	},
	heading: {
		// fontSize: theme.typography.pxToRem(18),
		...theme.typography.display.bold
	},
	statGrid: {
		maxWidth: theme.spacing(100)
	},
	statNum: {
		lineHeight: 1,
		paddingBottom: theme.spacing(1),
		...theme.typography.display.bold
	},
	statLabel: {
		fontSize: theme.typography.pxToRem(12)
	},
	statDiff: {
		display: "inline",
		position: "absolute",
		paddingLeft: theme.spacing(1),
		// left: 5,
		// top: -5,
		"& *": {
			fontSize: theme.typography.pxToRem(10),
			fontStyle: "italic"
		}
	},
	statNatNote: {
		position: "absolute",
		top: theme.spacing(-4),
		left: "40%",
		whiteSpace: "nowrap",
		"& span": {
			paddingLeft: theme.spacing(5),
			paddingBottom: theme.spacing(2)
		},
		"& img": {
			position: "absolute",
			left: theme.spacing(1),
			top: theme.spacing(1)
		}
	}
}))(Stats);

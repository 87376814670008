import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Grid, Typography, useTheme, withStyles } from "@material-ui/core";
import { Scale } from "@hyperobjekt/scales";
import "@hyperobjekt/scales/dist/style.css";
import { formatNumber, roundNumber } from "../../../utils";

const MapLegend = ({
	classes,
	className,
	value,
	maxVals,
	showMarker,
	...props
}) => {
	const theme = useTheme();
	// const formatter = (val) => Number.isFinite(val) ? Math.round(val * 100) / 100 : "";
	return (
		<Grid
			container
			justifyContent="flex-end"
			className={clsx(classes.root, className)}
			{...props}
		>
			<Grid item>
				<Box className={classes.noData}>
					<Typography component="span">No data</Typography>
				</Box>
			</Grid>
			<Grid item>
				<Scale
					type="continuous"
					min={0}
					// max={roundNumber(maxVals)}
					max={maxVals}
					colors={[
						theme.palette.map.fill.min,
						theme.palette.map.fill.mid,
						theme.palette.map.fill.max
					]}
					// nice
				>
					<Typography>Filings rate</Typography>
					{showMarker ? (
						<Scale.Marker pointer value={value}>
							<Typography
								style={{
									fontSize: "0.75rem",
									position: "absolute",
									top: -36
								}}
							>
								{formatNumber(value)}
							</Typography>
						</Scale.Marker>
					) : (
						""
					)}
					<Scale.Colors />
					<Scale.Ticks
						endTicks
						tickFormat={t =>
							t >= maxVals ? `> ${formatNumber(t)}%` : `${formatNumber(t)}%`
						}
					/>
				</Scale>
			</Grid>
		</Grid>
	);
};

MapLegend.defaultProps = {};
MapLegend.propTypes = {};

export default withStyles(theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(2),
		"& *": {
			fontSize: theme.typography.pxToRem(10)
		},
		"& .HypScale-root": {
			"& .MuiTypography-root": {
				width: "100%",
				position: "absolute",
				top: 14,
				left: 0,
				textAlign: "center"
			}
		},
		"& .HypScale-ticks": {
			overflow: "visible",
			"& g.tick:last-of-type text": {
				textAnchor: "end"
			}
		}
	},
	noData: {
		display: "flex",
		textAlign: "right",
		paddingRight: "8px",
		paddingBottom: theme.spacing(2),
		"&::before": {
			content: '""',
			width: theme.spacing(2),
			height: theme.spacing(2),
			display: "inline-block",
			marginRight: theme.spacing(1),
			background: theme.palette.map.fill.default,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: theme.palette.map.stroke.default
		}
	}
}))(MapLegend);

import React from "react";
import { Link } from "gatsby";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import clsx from "clsx";
import Block from "gatsby-theme-hypersite/block";
import { formatDates } from "../../../utils";

const DataContext = ({
	name,
	moratoria,
	context,
	classes,
	className,
	children,
	...props
}) => {
	return (
		<Block className={clsx(classes.root, className)}>
			<Typography component="h2" variant="h4" gutterBottom>
				Eviction Filings and Defaults in {name}
			</Typography>

			<Typography paragraph>
				During the COVID-19 pandemic, there&nbsp;
				{moratoria
					? `${
							moratoria.split("\\n").length > 1
								? "were state-wide moratoria"
								: "was a state-wide moratorium"
					  }
						on evictions from ${formatDates(moratoria)}.`
					: "was no state-wide moratorium on evictions."}
			</Typography>

			<Typography paragraph>
				The CDC moratorium was in place from September 4, 2020 through August
				26, 2021.
			</Typography>

			{context.length ? (
				<Typography
					paragraph
					dangerouslySetInnerHTML={{
						__html: context
					}}
				></Typography>
			) : (
				""
			)}
		</Block>
	);
};

export default withStyles(theme => ({
	root: {}
}))(DataContext);

import React from "react";
import clsx from "clsx";
import { Box, Typography, withStyles } from "@material-ui/core";
import { Container, Navigation } from "@hyperobjekt/material-ui-website";
import scrollTo from "gatsby-plugin-smoothscroll";

const JumpLinks = ({ sections, classes, className, ...props }) => {
	const ids = Object.keys(sections);
	const links = sections.map(s => ({
		...s,
		link: `#${s.slug}`
	}));

	return (
		<Box className={clsx(classes.root, className)}>
			<Container className={classes.container}>
				<Navigation links={links} />
				{/*<a href="#about">TEST</a>*/}
			</Container>
		</Box>
	);
};

JumpLinks.propTypes = {};

export default withStyles(theme => ({
	root: {
		position: "sticky",
		top: theme.layout.headerHeight,
		zIndex: 1000,
		padding: theme.spacing(2, 0),
		background: theme.palette.background.paper,
		borderTop: `2px solid ${theme.palette.background.paperSecondary}`,
		boxShadow: theme.shadows[2],
		overflowX: "scroll",
		"& .HypNavigation-root": {},
		"& .HypNavigation-list": {
			// width: "100%",
			margin: "auto",
			display: "flex"
			// justifyContent: "center",
			// margin: theme.spacing(0, -1),
		},
		"& .HypNavigation-listItem": {
			width: "auto"
		},
		"& .HypNavigation-link": {
			color: theme.palette.text.secondary,
			"&:hover": {
				color: theme.palette.red.main,
				textDecoration: "none"
			}
		}
	},
	container: {
		// paddingLeft: 0,
		// paddingRight: 0,
	}
}))(JumpLinks);

import React from "react";
import { Button, Box, Typography } from "@material-ui/core";
import { Block, Container } from "@hyperobjekt/material-ui-website";
import { Blockquote } from "./common";
// import Typography from "./typography";
import mdxComponents from "gatsby-theme-hypersite/src/gatsby-theme-hypercore/mdx";
// import Hero from "./sections/hero";
// import Modal from "./modal"

const components = {
	...mdxComponents,
	section: Container,
	span: props => <Typography component="span" {...props} />,
	// Hero,
	Block,
	// Blockquote,
	blockquote: props => <Typography component="blockquote" {...props} />,
	// Typography,
	Box,
	Button
	// Modal,
};

export default components;

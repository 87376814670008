import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import clsx from "clsx";
import {
	CarouselProvider,
	Slider,
	Slide,
	Image,
	ButtonBack,
	ButtonNext
} from "pure-react-carousel";
import Block from "gatsby-theme-hypersite/block";

const DEFAULT_LABEL = "New Update";
const DEFAULT_PROMPT = "Read More";

const Carousel = ({ slides, classes, className, ...props }) => {
	return (
		<Block className={clsx(classes.root, className)}>
			<CarouselProvider
				// naturalSlideWidth={100}
				// naturalSlideHeight={125}
				naturalSlideWidth={true}
				totalSlides={slides && slides.length}
				visibleSlides={2}
				// infinite={true}
				step={1}
			>
				<Grid container>
					<Grid item xs={12} lg={4}>
						<Grid container>
							<Grid item>
								<Typography component="h3" variant="h3">
									Our Latest Updates
								</Typography>
							</Grid>
							<Grid item>
								<ButtonGroup
									size="small"
									variant="outlined"
									disableElevation
									aria-label=""
								>
									<Button
										component="div"
										// variant="outlined"
										role="presentation"
									>
										<ButtonBack />
									</Button>

									<Button
										component="div"
										// variant="outlined"
										role="presentation"
									>
										<ButtonNext />
									</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={8} style={{ overflow: "hidden" }}>
						<Slider className={classes.slider}>
							{slides.map((slide, i) => (
								<Slide key={i} index={i}>
									<Box className={classes.thumb}>
										<Box>
											{slide.thumb ? (
												<Image src={slide.thumb} alt="" />
											) : (
												<Box className={classes.placeholder} />
											)}
											{slide.label ? (
												<Typography variant="h6">
													{slide.label || DEFAULT_LABEL}
												</Typography>
											) : null}
										</Box>
										q
									</Box>

									<Typography component="h3" variant="h4">
										{slide.title}
									</Typography>

									<Button
										variant="text"
										role="presentation"
										href={slide.url}
										target={
											/^((http|https):\/\/)/.test(slide.url) ? "_blank" : ""
										}
									>
										{slide.prompt || DEFAULT_PROMPT}
									</Button>
								</Slide>
							))}
						</Slider>
					</Grid>
				</Grid>
			</CarouselProvider>
		</Block>
	);
};

export default withStyles(theme => ({
	root: {
		"& .carousel__slider": {
			margin: theme.spacing(0, -2)
		},
		"& .carousel__slider-tray": {
			display: "flex",
			transition: theme.transitions.create(["transform"])
		},
		"& .carousel__slide": {
			paddingBottom: 0
		},
		"& .carousel__inner-slide": {
			padding: theme.spacing(0, 2)
		},
		"& .MuiTypography-h4": {
			margin: theme.spacing(2, 0)
		},
		"& .MuiButton-text": {
			margin: 0,
			color: theme.palette.red.main
		},
		"& .MuiButtonGroup-root": {
			[theme.breakpoints.down("lg")]: {
				marginLeft: theme.spacing(4)
			},
			[theme.breakpoints.up("lg")]: {
				margin: theme.spacing(2, 0)
			},
			"& .MuiButton-root": {
				width: theme.spacing(6),
				height: theme.spacing(5),
				color: theme.palette.background.dark,
				"&:first-child": {
					borderTopLeftRadius: theme.shape.borderRadius,
					borderBottomLeftRadius: theme.shape.borderRadius
				},
				"&:last-child": {
					borderTopRightRadius: theme.shape.borderRadius,
					borderBottomRightRadius: theme.shape.borderRadius
				}
			},
			"& .carousel__back-button, & .carousel__next-button": {
				width: "100%",
				height: "100%",
				position: "absolute",
				left: 0,
				top: 0,
				appearance: "none",
				background: "none",
				padding: 0,
				border: 0,
				cursor: "pointer",
				"&::before": {
					content: `""`,
					borderStyle: "solid",
					display: "inline-block",
					padding: 3,
					position: "absolute",
					left: "50%",
					top: "50%",
					marginTop: -3,
					marginLeft: -3,
					transform: "rotate(-45deg)",
					pointerEvents: "none"
				}
			},
			"& .carousel__back-button::before": {
				borderWidth: "1px 0px 0px 1px"
			},
			"& .carousel__next-button::before": {
				borderWidth: "0 1px 1px 0"
			}
		}
	},
	slider: {
		display: "flex",
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(6)
		}
	},
	thumb: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingBottom: "75%",
		overflow: "hidden",
		"& .MuiBox-root": {
			width: "100%",
			height: "100%",
			position: "absolute",
			left: 0,
			top: 0
		},
		"& .carousel__image": {
			width: "100%",
			height: "100%",
			objectFit: "cover"
		},
		"& .MuiTypography-h6": {
			padding: theme.spacing(1, 2),
			position: "absolute",
			left: theme.spacing(2),
			bottom: theme.spacing(2),
			zIndex: 2,
			color: theme.palette.text.light,
			backgroundColor: theme.palette.red.main,
			borderRadius: 3,
			"&:first-child": {
				position: "static",
				display: "inline"
			}
		}
	},
	placeholder: {
		width: "100%",
		height: "100%",
		backgroundColor: theme.palette.blue.dark
	}
	// slidesWrapper: {
	// 	flexWrap: "nowrap"
	// },
	// slideItem: {
	// 	float: "left"
	// }
}))(Carousel);

import React from "react";
import clsx from "clsx";
import { Box, SvgIcon, Typography, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// import { MenuIcon as MuiMenuIcon } from "@material-ui/icons";
// import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
// import Close from "@material-ui/icons/Close"
// import DarkOn from "@material-ui/icons/BrightnessHigh"
// import DarkOff from "@material-ui/icons/Brightness4"

const BurgerIcon = ({ classes, className, ...props }) => {
	// const MenuIconX = (props) => {
	return (
		<Box className={clsx(classes.root, className)}>
			{/*<SvgIcon aria-hidden="true" {...props}>
				<path d="M5 9H19V10H5V9Z" />
				<path d="M5 9H19V10H5V9Z" />
				<path d="M9 13H19V14H9V13Z" />
				<path d="M9 13H19V14H9V13Z" />
			</SvgIcon>*/}
			<MenuIcon />
			{/*<Typography>
				Menu
			</Typography>*/}
		</Box>
	);
};

export default withStyles(theme => ({
	root: {
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: 1
	}
}))(BurgerIcon);

import React from "react";
import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";
import {
	Container,
	Grid,
	Typography,
	Button,
	withStyles
} from "@material-ui/core";

import { Block } from "@hyperobjekt/material-ui-website";

const Feed = ({ title, items, classes, children, ...props }) => {
	return (
		<Block className={clsx(classes.root)}>
			<Container maxWidth="md">
				<Typography component="h2" variant="h3" align="center" gutterBottom>
					{title}
				</Typography>

				{items.map((item, i) => {
					const { frontmatter } = item;
					const { meta } = frontmatter;
					const url = frontmatter.store
						.filter(s => s.key === "url")
						.map(s => s.value)[0];
					return (
						<Grid container className={classes.item} key={i}>
							<Grid item xs={4}>
								{frontmatter.image && (
									//TODO: OPTIMIZE IMAGES
									<img src={frontmatter.image} alt="" />
								)}
							</Grid>
							<Grid item xs={8}>
								<Typography component="h2" variant="h4">
									{frontmatter.name}
								</Typography>

								{meta.author ? (
									<Typography paragraph>{meta.author.join(", ")}</Typography>
								) : null}

								{item.body && <MDXRenderer>{item.body}</MDXRenderer>}

								<Button
									role="presentation"
									variant="contained"
									target="_blank"
									href={url}
								>
									Read more
								</Button>
							</Grid>
						</Grid>
					);
				})}
			</Container>
		</Block>
	);
};

export default withStyles(theme => ({
	root: {
		"& .MuiTypography-h3": {
			color: theme.palette.blue.main
		}
	},
	item: {
		margin: theme.spacing(10, 0, 12, 0),
		"& .MuiTypography-h4": {
			marginBottom: theme.spacing(2)
		},
		"& .MuiButton-root": {
			marginTop: theme.spacing(3)
		}
	}
}))(Feed);

import React from "react";
// import clsx from "clsx";
import { default as BaseHeader } from "gatsby-theme-hypersite/src/header/header";
import { withStyles } from "@material-ui/core";

/**
 * Re-uses the theme header but adds a color change
 * when scrolling for the homepage by utilizing the
 * "shrink" functionality.
 */
function Header({ classes, ...props }) {
	return (
		<BaseHeader
			classes={classes}
			height={null}
			stickyOffset={0}
			// shrink={withStyles(t => t.layout.headerHeight)}
			shrinkOffset={0}
			{...props}
		></BaseHeader>
	);
}

export default withStyles(theme => ({
	root: {
		height: theme.layout.headerHeight,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[2],
		transition: theme.transitions.create(["background-color", "transform"]),
		transform: `translateY(0px)`,
		"& + .HypHeader-offset": {
			height: theme.layout.headerHeight
			//   display: "none"
		},
		"& .MuiBox-root": {
			height: "100%"
		}
	}
	// stuck: {
	//   // position: "fixed",
	//   top: -64,
	//   transform: `translateY(${theme.layout.headerHeight})`,
	//   backgroundPosition: "0 0px",
	//   "& + .HypHeader-offset": {
	//     minHeight: `none!important`,

	//   },
	//   "&.HypHeader-shrunk": {
	//     backgroundColor: theme.palette.background.paper,
	//   },
	// },
}))(Header);

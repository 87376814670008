import React from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import Papa from "papaparse";
import { Box, Button, Grid, Typography, withStyles } from "@material-ui/core";
import Block from "gatsby-theme-hypersite/block";
import { downloadData, slugify } from "../../../utils";
import { TooltipWrapper } from "../../common";
import { policySummarySchema, hasPolicySummary } from "./schema";

const PolicySummary = ({ classes, className, data, ...props }) => {
	return hasPolicySummary(data) ? (
		<Block className={clsx(classes.root, className)} {...props}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Typography component="h2" variant="h4" gutterBottom>
						Policy Summary for {data.name}
					</Typography>

					<Typography paragraph>
						The policy summary below provides a "snapshot" of eviction laws and
						procedures. Unless otherwise specified in a gray information button
						below, the information presented here was current as of January 1,
						2021. If you have questions or concerns, please reach out to us at
						civilcourtdata@lsc.gov.
					</Typography>
				</Grid>

				<Grid item xs={6}>
					<Grid container spacing={2}>
						<Grid item>
							<Link to="/about/faq">
								<Button variant="outlined" role="presentation">
									Go to FAQ
								</Button>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container spacing={3} className={classes.grid}>
				{policySummarySchema.map((s, i) => (
					<Grid item md={6} key={i}>
						<Box mb={10}>
							<Typography
								component="h3"
								variant="h5"
								gutterBottom
								style={{
									backgroundImage: `url(/images/icon-stage-${s.key}.svg)`
								}}
							>
								{s.label}
							</Typography>
							<Grid container spacing={8}>
								{s.variables.map((v, ii) => (
									//data[v.key] ?
									<Grid className={classes.row} item key={ii}>
										<Grid container spacing={8}>
											<Grid item xs={8}>
												<Typography component="span">{v.label}</Typography>
												&nbsp;
												<TooltipWrapper
													icon={true}
													content={data[`${v.key}_info`]}
												></TooltipWrapper>
											</Grid>
											<Grid item xs={4}>
												{data[v.key] ? (
													<Box className={classes[v.type]}>
														{v.type === "boolean" ? (
															<img
																src={`/images/icon-${
																	data[v.key] === "Yes" ? "true" : "false"
																}.svg`}
																alt={data[v.key]}
															/>
														) : (
															""
														)}
														{v.type === "number" ? (
															<Typography component="span">
																{isNaN(data[v.key])
																	? data[v.key]
																	: parseInt(data[v.key])}
															</Typography>
														) : (
															""
														)}
														{v.type === "string" ? (
															<Typography component="span">
																{data[v.key]}
															</Typography>
														) : (
															""
														)}
														{v.type === "icon" ? (
															<>
																<img src={`/images/icon-${v.key}.svg`} alt="" />
																<Typography
																	component="span"
																	className={classes.string}
																>
																	{data[v.key]}
																</Typography>
															</>
														) : (
															""
														)}
													</Box>
												) : (
													"N/A"
												)}
											</Grid>
										</Grid>
									</Grid>
									//: ""
								))}
							</Grid>
						</Box>
					</Grid>
				))}
			</Grid>
		</Block>
	) : (
		""
	);
};

PolicySummary.propTypes = {};

export default withStyles(theme => ({
	root: {
		"& .MuiTypography-h5": {
			display: "inline-block",
			minHeight: theme.spacing(4),
			paddingRight: theme.spacing(6),
			backgroundSize: theme.spacing(4),
			backgroundRepeat: "no-repeat",
			backgroundPosition: "right center"
		},
		"& > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiGrid-container > .MuiGrid-item":
			{
				width: "100%"
				// marginBottom: theme.spacing(12),
			}
		// [theme.breakpoints.down("md")]: {
		// 	padding: theme.spacing(12, 0),
		// }
	},
	grid: {
		marginTop: theme.spacing(12)
	},
	row: {
		width: "100%"
	},
	boolean: {},
	number: {
		"& span": {
			minWidth: theme.spacing(4),
			height: theme.spacing(4),
			padding: theme.spacing(0, 0.5),
			display: "inline-block",
			textAlign: "center",
			borderRadius: "999px",
			borderWidth: 1,
			borderColor: theme.palette.text.secondary,
			borderStyle: "solid"
		}
	},
	string: {
		"& *": {
			fontSize: theme.typography.pxToRem(10)
		}
	},
	icon: {
		display: "flex",
		"& *": {
			fontSize: theme.typography.pxToRem(10),
			lineHeight: 1.4
		},
		"& img": {
			width: theme.spacing(3),
			height: theme.spacing(3),
			marginRight: theme.spacing(2)
		},
		"& span": {
			maxWidth: theme.spacing(18)
		}
	}
}))(PolicySummary);

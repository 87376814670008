import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Button, Typography, withStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import {
	DatePicker,
	DesktopDatePicker,
	LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DateSelector = ({
	classes,
	className,
	value,
	options,
	variable,
	minDate,
	maxDate,
	startDate,
	endDate,
	onStartDateChange,
	onEndDateChange,
	onChange,
	...props
}) => {
	const [startDateOpen, onStartDateOpen] = useState(false);
	const [endDateOpen, onEndDateOpen] = useState(false);
	const anchorEl = useRef(null);

	const DateTextField = ({ callback, ...params }) => {
		return (
			<TextField
				{...params}
				variant="standard"
				// onClick={() => {
				// 	console.log(true);
				// 	callback(true);
				// }}
				// onBlur={() => {
				// 	console.log(false);
				// 	callback(false);
				// }}
				// InputProps={{
				// 	endAdornment: null,
				// }}
			/>
		);
	};

	return (
		<Box ref={anchorEl} className={clsx(classes.root, className)}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Typography component="span">Between&nbsp;</Typography>

				<DesktopDatePicker
					minDate={minDate}
					maxDate={maxDate}
					value={startDate}
					inputFormat="MM/dd/yyyy"
					disableFuture={true}
					PopperProps={{
						placement: "bottom-end",
						anchorEl: anchorEl.current
					}}
					onChange={onStartDateChange}
					renderInput={params => <DateTextField {...params} />}
				/>

				<Typography component="span">&nbsp;and&nbsp;</Typography>

				<DesktopDatePicker
					minDate={minDate}
					maxDate={maxDate}
					value={endDate}
					inputFormat="MM/dd/yyyy"
					disableFuture={true}
					PopperProps={{
						placement: "bottom-end",
						anchorEl: anchorEl.current
					}}
					onChange={onEndDateChange}
					renderInput={params => <DateTextField {...params} />}
				/>
			</LocalizationProvider>
		</Box>
	);
};

DateSelector.propTypes = {};

export default withStyles(theme => ({
	root: {
		height: "100%",
		display: "inline-flex",
		alignContent: "center",
		"& *": {
			margin: "auto",
			fontSize: theme.typography.pxToRem(12)
		},
		"& .MuiButtonBase-root": {
			borderRadius: 5
		},
		"& .MuiDateSelectorButton-root.Mui-selected": {
			color: theme.palette.red.main,
			backgroundColor: theme.palette.red.light,
			borderColor: theme.palette.red.main,
			borderLeft: 1,
			position: "relative",
			zIndex: 2
		},
		"& .MuiInputAdornment-root, & .MuiIconButton-root": {
			margin: 0,
			padding: 0
		},
		"& .MuiInput-root": {
			color: theme.palette.red.main,
			...theme.typography.body.bold,
			"&::before, &::after": {
				borderWidth: 0
			}
		},
		"& .MuiInputBase-input": {
			width: theme.spacing(10),
			padding: 0,
			fontSize: theme.typography.pxToRem(12),
			...theme.typography.body.medium
		},
		"& .MuiInput-underline": {
			"&::before": {
				bottom: -4
			},
			"&:hover::before, &:focus-within::before": {
				borderBottomWidth: "1px !important"
			}
		}
	}
}))(DateSelector);

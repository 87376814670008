import React from "react";
import Hero from "gatsby-theme-hypersite/src/main/hero";
import { Typography, withStyles } from "@material-ui/core";
// import { compactTitleTypography } from "../../gatsby-theme-hypercore/theme";
// import PostMeta from "./post-meta";

const PostHero = ({ title, lede, image, classes, children, ...props }) => {
	// const { root, container, content, imageWrapper, image } = classes;
	return (
		<Hero
			// classes={{ root, container, content, imageWrapper, image }} {...props}
			color="text.light"
			bgcolor="background.dark"
			height={550}
			alt=""
		>
			<Typography component="h1" variant="h1">
				{title}
			</Typography>
			{children}
		</Hero>
	);
};

export default withStyles(theme => ({
	root: {
		paddingTop: 0,
		"& h1": {
			// maxWidth: "7em",
			marginBottom: theme.spacing(2)
		}
	},
	container: {
		maxWidth: "unset",
		marginTop: "auto",
		display: "block"
	},
	content: {
		position: "relative",
		zIndex: 3
	},
	imageWrapper: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 2,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&::before": {
			content: "''",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			backgroundImage: "linear-gradient(90deg, black, transparent)"
		}
	},
	image: {
		width: "100%",
		height: "100%",
		objectFit: "cover"
	}
}))(PostHero);

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-county-js": () => import("./../../../src/layouts/county.js" /* webpackChunkName: "component---src-layouts-county-js" */),
  "component---src-layouts-state-js": () => import("./../../../src/layouts/state.js" /* webpackChunkName: "component---src-layouts-state-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-releases-js": () => import("./../../../src/templates/releases.js" /* webpackChunkName: "component---src-templates-releases-js" */),
  "component---src-templates-report-js": () => import("./../../../src/templates/report.js" /* webpackChunkName: "component---src-templates-report-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-tracker-js": () => import("./../../../src/templates/tracker.js" /* webpackChunkName: "component---src-templates-tracker-js" */)
}


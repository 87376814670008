import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import {
	Table as MuiTable,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableSortLabel,
	TablePagination,
	Typography,
	Box,
	Button,
	darken,
	styled,
	withStyles,
	useTheme,
	useMediaQuery
} from "@material-ui/core";
import clsx from "clsx";
import { JurisdictionSelector, TooltipWrapper } from "../common";
import { getUrl, formatDate, formatDates } from "../../utils";

const ASC = "asc";
const DESC = "desc";
const DEFAULT_ORDER_BY = "name";
const DEFAULT_DIRECTION = DESC;
const DEFAULT_ROWS_PER_PAGE = 5;
const HIGHLIGHT_PADDING = 3;

const Table = ({
	data,
	schema,
	defaultOrderBy,
	defaultDirection,
	defaultRowsPerPage,
	selectBy,
	palette,
	highlight,
	classes,
	className,
	...props
}) => {
	const [rows, setRows] = useState([]);
	const [sortedRows, setSortedRows] = useState([]);
	const [selectOptions, setSelectOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [page, setPage] = useState(0);
	const [orderBy, setOrderBy] = useState(defaultOrderBy || DEFAULT_ORDER_BY);
	const [direction, setDirection] = useState(
		defaultDirection || DEFAULT_DIRECTION
	);
	const [rowsPerPage, setRowsPerPage] = useState(
		defaultRowsPerPage || DEFAULT_ROWS_PER_PAGE
	);
	const [highlightWidth, setHighlightWidth] = useState(null);
	const [highlightLeft, setHighlightLeft] = useState(null);
	const highlightStartRef = useRef(null);
	const highlightEndRef = useRef(null);

	useEffect(() => {
		if (highlight) resizeHighlight();
	}, []);

	useEffect(() => {
		const newSelectOptions = data
			.map(r => r[selectBy])
			.filter((val, i, arr) => arr.indexOf(val) === i)
			.sort((a, b) => a.localeCompare(b));
		setSelectOptions(newSelectOptions);
		sortRows();
	}, [rows, direction, orderBy]);

	useEffect(() => {
		const newRows =
			selectBy && selectedOption
				? data.filter(d => d[selectBy] === selectedOption)
				: data;
		setRows(newRows);
	}, [data, selectedOption]);

	const formatString = str => {
		return isNaN(str) ? str : Number(str).toLocaleString("en-US");
	};

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = e => {
		setRowsPerPage(+e.target.value);
		setPage(0);
	};

	const handleDirectionChange = key => {
		const newOrderBy = orderBy === key && direction === ASC ? DESC : ASC;
		setDirection(newOrderBy);
		setOrderBy(key);
	};

	const handleOptionSelect = val => {
		setSelectedOption(val);
	};

	const sortRows = data => {
		const newSortedRows = rows.sort((a, b) => {
			if (!isNaN(a[orderBy])) {
				if (direction === ASC) return Number(a[orderBy]) - Number(b[orderBy]);
				if (direction === DESC) return Number(b[orderBy]) - Number(a[orderBy]);
			} else {
				if (direction === ASC)
					return a[orderBy] ? a[orderBy].localeCompare(b[orderBy]) : null;
				if (direction === DESC)
					return b[orderBy] ? b[orderBy].localeCompare(a[orderBy]) : null;
			}
		});
		setSortedRows(newSortedRows);
	};

	const resizeHighlight = () => {
		const width =
			(100 / schema.length) * (highlight.end - highlight.start + 1) +
			HIGHLIGHT_PADDING;
		const left =
			(100 / schema.length) * highlight.start - HIGHLIGHT_PADDING * 1.5;
		setHighlightWidth(width);
		setHighlightLeft(left);
	};

	const tooltipContent = (str, key, data) => {
		let formatter;
		const strMatch = str ? str.match(/\{\{(.*)\}\}/) : null;
		if (strMatch) {
			switch (strMatch[1]) {
				case "date":
					formatter = formatDate;
					break;
				case "dates":
					formatter = formatDates;
					break;
				// default:
				// 	formatter = (str) => str;
				// 	break;
			}
			return str.replace(
				strMatch[0],
				formatter(data[`${key}_${strMatch[1]}`], {
					year: "numeric",
					month: "long"
				})
			);
		}
		return str;
	};

	return (
		<Box className={clsx(classes.root, className)} {...props}>
			<JurisdictionSelector
				className={classes.selector}
				options={selectOptions}
				label="Limit county results to"
				handleChange={handleOptionSelect}
			/>

			{rows ? (
				<TableContainer
					style={
						highlight
							? {
									paddingTop: "5rem"
							  }
							: null
					}
				>
					<MuiTable stickyHeader>
						<TableHead>
							<TableRow>
								{schema.map((s, i) => (
									<TableCell
										key={i}
										sortDirection={orderBy === s.key ? direction : false}
									>
										<TableSortLabel
											active={orderBy === s.key}
											direction={direction}
											onClick={() => handleDirectionChange(s.key)}
										>
											{s.label}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedRows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((d, i) => (
									<TableRow key={i}>
										{schema.map((s, i) => (
											<TableCell key={i} component="th" scope="row">
												{d[s.key] ? (
													<>
														{s.link ? (
															<Button
																role="presentation"
																variant="text"
																href={getUrl(
																	"data",
																	"eviction",
																	s.label === "County" ? d.state_name : null,
																	d[s.key]
																)}
															>
																{d[s.key]}
															</Button>
														) : (
															<TooltipWrapper
																content={tooltipContent(s.tooltip, s.key, d)}
																icon={true}
															>
																{formatString(d[s.key])}
																{s.percent ? "%" : ""}
															</TooltipWrapper>
														)}

														{/*{!s.link ?
																<>
																	{s.tooltip
																		? <TooltipWrapper
																				content={s.tooltip}
																				icon={true}>
																				{formatString(d[s.key])}
																				{s.percent ? "%" : ""}
																			</TooltipWrapper>
																		: s.key === "filings_latest"
																			? <TooltipWrapper
																					content={`Filings in ${formatDate(d[`filings_latest_date`], {
																						year: "numeric",
																						month: "long"
																					})}`}
																					icon={true}>
																					{formatString(d[s.key])}
																					{s.percent ? "%" : ""}
																				</TooltipWrapper>
																			: <>
																					{formatString(d[s.key])}
																					{s.percent ? "%" : ""}
																				</>
																			}
																</>
														: ""}*/}
													</>
												) : (
													"N/A"
												)}
											</TableCell>
										))}
									</TableRow>
								))}
						</TableBody>
					</MuiTable>
					{/*{highlight ?
						<Box
							style={{
								width: `${highlightWidth}%`,
								height: "100%",
								left: `${highlightLeft}%`,
								top: "0",
							}}
							className={classes.highlight}>
							{highlight.title ? 
								<Typography
									component="h3"
									variant="h3">
									<span>
										{highlight.title}
									</span>
								</Typography>
							: null}
						</Box>
					: null}*/}
				</TableContainer>
			) : null}
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	);
};

Table.propTypes = {};

export default withStyles(theme => ({
	root: {
		backgroundColor: "transparent",
		"& .MuiTableContainer-root": {
			margin: theme.spacing(4, 0),
			overflowX: "visible"
		},
		"& .MuiTableCell-root": {
			// paddingLeft: 0,
			padding: theme.spacing(1, 1, 1, 0)
			// "&:not(:first-child)": {
			// 	textAlign: "right",
			// },
		},
		"& .MuiTableHead-root": {},
		"& .MuiTableCell-head": {
			fontWeight: "bold",
			background: "transparent",
			...theme.typography.body.light
		},
		"& .TableContainer-root": {
			position: "relative",
			overflow: "visible"
		},
		"& .MuiTable-root": {
			position: "relative",
			zIndex: 1
		},
		"& .MuiTableCell-body": {
			"& *": {
				// ...theme.typography.body.bold,
			}
		},
		"& .MuiButton-text": {
			marginLeft: 0,
			marginRight: 0
		}
	}
	// selector: {
	// 	marginBottom: theme.spacing(6),
	// }
	// highlight: {
	// 	height: "calc(100% + 2rem)",
	// 	position: "absolute",
	// 	top: 0,
	// 	left: 0,
	// 	zIndex: 2,
	// 	color: theme.palette.red.main,
	// 	border: "1px solid currentColor",
	// 	// borderRadius: theme.shape.borderRadius,
	// 	"& .MuiTypography-h3": {
	// 		padding: theme.spacing(3, 0),
	// 		// height: theme.spacing(10),
	// 		display: "flex",
	// 		fontSize: theme.typography.pxToRem(16),
	// 		textAlign: "center",
	// 		textTransform: "uppercase",
	// 		borderBottom: "1px solid currentColor",
	// 		...theme.typography.body.bold.fontWeight,
	// 		"& span": {
	// 			margin: "auto",
	// 		}
	// 	},
	// },
}))(Table);

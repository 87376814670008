import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import Block from "gatsby-theme-hypersite/block";
import Stats from "../../stats";

const statsSchema = [
	{
		key: "rent_burdened",
		label: "Rent burdened residents",
		percent: true
	},
	{
		key: "poverty_rate",
		label: "Poverty rate",
		percent: true
	}
];

const MoreStats = ({ level, data, national, classes, className, ...props }) => {
	return (
		<Block className={clsx(classes.root, className)} {...props}>
			<Typography component="h2" variant="subtitle1" gutterBottom>
				About this {level}
			</Typography>
			<Stats schema={statsSchema} data={data} national={national} />
		</Block>
	);
};

MoreStats.propTypes = {};

export default withStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paperSecondary,
		paddingTop: theme.spacing(12),
		paddingBottom: theme.spacing(12),
		marginTop: 0
	}
	// heading: {
	//   fontSize: theme.typography.pxToRem(18),
	//   ...theme.typography.display.bold
	// },
	// statGrid: {
	//   maxWidth: theme.spacing(100),
	//   paddingTop: theme.spacing(6),
	// },
	// statNum: {
	//   fontSize: theme.typography.pxToRem(42),
	//   lineHeight: 1,
	//   paddingBottom: theme.spacing(1),
	//   ...theme.typography.display.bold
	// },
	// statDiff: {
	//   position: "absolute",
	//   // top: 0,
	// },
	// statLabel: {
	//   fontSize: theme.typography.pxToRem(14),
	// }
}))(MoreStats);

import React from "react";
import { clsx } from "clsx";
import Hero from "gatsby-theme-hypersite/src/main/hero";
import { Box, Typography, withStyles } from "@material-ui/core";
// import { compactTitleTypography } from "../../gatsby-theme-hypercore/theme";
// import PostMeta from "./post-meta";

const PageHero = ({
	title,
	lede,
	image,
	gradientVariant,
	classes,
	location,
	children,
	...props
}) => {
	let useDefaultImage = false;
	let isResearchPage = location.pathname.indexOf("/research/") > -1;
	let defaultImage =
		isResearchPage === true
			? "/images/uploads/LSC_23001_CCDI-WebImages_ResearchReports_1920x800.jpg"
			: "/images/uploads/default_hero_1825x550.jpg";

	if (!image) {
		image = defaultImage;
		useDefaultImage = true;
		delete classes.hasImage;
	}

	const heroClasses = classes
		? (({
				root,
				container,
				imageWrapper,
				image,
				content,
				gradient,
				overlay
		  }) => ({
				root,
				container,
				imageWrapper,
				image,
				content,
				gradient,
				overlay
		  }))(classes)
		: {};

	return (
		<Hero
			{...props}
			classes={heroClasses}
			color="text.light"
			bgcolor="blue.dark"
			height={image && useDefaultImage === false ? 550 : ""}
			image={image}
			alt=""
		>
			<Box className={clsx(classes.align, classes.hasImage)}>
				{title ? (
					<Typography
						component="h1"
						variant="h1"
						className={classes.title}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				) : null}
				{lede ? (
					<Typography
						paragraph
						className={classes.lede}
						dangerouslySetInnerHTML={{ __html: lede }}
					/>
				) : null}
				{children}
			</Box>
		</Hero>
	);
};

export default withStyles(theme => ({
	root: {
		paddingTop: 0,
		paddingBottom: 0
		// backgroundColor: theme.palette.blue.dark,
		// "& h1": {
		// marginBottom: theme.spacing(2),
		// },
	},
	container: {
		alignItems: "unset",
		justifyContent: "unset"
	},
	content: {
		width: "100%",
		height: "100%",
		position: "relative",
		zIndex: 3,
		margin: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	imageWrapper: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 2,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&::before": {
			content: "''",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: 0,
			left: 0,
			backgroundImage: props =>
				props.image === undefined || props.image === null
					? ""
					: props.gradientVariant === true
					? `linear-gradient(360deg, ${theme.palette.blue.dark}, transparent)`
					: "linear-gradient(90deg, black, transparent)"
		}
	},
	image: {
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	align: {
		margin: 0,
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	lede: {
		marginTop: theme.spacing(4)
	},
	hasImage: {
		width: "auto",
		height: "auto",
		alignItems: "flex-start",
		marginTop: "auto",
		marginRight: "auto",
		paddingBottom: theme.spacing(6)
	}
}))(PageHero);

export const policySummarySchema = [
	{
		key: "notice",
		label: "Notice stage",
		variables: [
			{
				key: "withhold_rent",
				label:
					"Does the law outline a process by which tenants can lawfully withhold rent in response to issues with their landlord?",
				type: "boolean"
			},
			{
				key: "notice_req_nonpay",
				label:
					"Are landlords required to give renters a notice to vacate before filing for eviction for nonpayment of rent?",
				type: "boolean"
			},
			{
				key: "notice_min_nonpay",
				label:
					"Minimum lawful period for notice to vacate for nonpayment of rent",
				type: "number"
			},
			{
				key: "notice_req_other",
				label:
					"Are landlords required to give renters a notice to vacate before filing for eviction for reasons other than nonpayment of rent?",
				type: "boolean"
			},
			{
				key: "notice_min_other",
				label:
					"Minimum lawful period for notice to vacate for reasons other than nonpayment of rent",
				type: "number"
			}
		]
	},
	{
		key: "filing",
		label: "Filing stage",
		variables: [
			{
				key: "just_cause",
				label:
					'Does the jurisdiction require landlords to have a "just cause" to file for eviction?',
				type: "boolean"
			},
			{
				key: "edp_require",
				label:
					"Does the jurisdiction require landlords to participate in an eviction diversion program prior to filing?",
				type: "boolean"
			},
			{
				key: "filing_min_nonpay",
				label:
					"Minimum time period between the day rent is due and the first day a landlord can file for eviction for nonpayment of rent",
				type: "number"
			},
			{
				key: "first_court",
				label: "Which courts have original jurisdiction over eviction cases?",
				type: "string"
			},
			{
				key: "filing_fee",
				label: "Total required fee to file an eviction case",
				type: "number"
			},
			{
				key: "notice_ls",
				label:
					"Is information on legal services required in the notice to vacate?",
				type: "string"
			}
		]
	},
	{
		key: "hearing",
		label: "Hearing stage",
		variables: [
			{
				key: "summons_min",
				label:
					'What is the minimum number of days a tenant must be served with a summons before they are required to "respond"?',
				type: "number"
			},
			{
				key: "summons_respond",
				label:
					'What must a tenant do to "respond" to a summons once it is received?',
				type: "icon"
			},
			{
				key: "summons_ls",
				label: "Is information on legal services required in the summons?",
				type: "boolean"
			},
			{
				key: "pay_after_filing",
				label:
					"Can tenants pay back rent owed to stop evictions for nonpayment after filing but before the first hearing?",
				type: "boolean"
			},
			{
				key: "right_to_counsel",
				label: "Is there an active right to counsel program in place?",
				type: "boolean"
			}
		]
	},
	{
		key: "decision",
		label: "Court decision stage",
		variables: [
			{
				key: "appeal_allowed",
				label: "Are tenants permitted to appeal an eviction judgment?",
				type: "boolean"
			},
			{
				key: "appeal_min",
				label:
					"Up to how many days after an eviction judgment can a tenant file an appeal?",
				type: "number"
			},
			{
				key: "appeal_bond",
				label: "Is the tenant required to pay a bond as part of the appeal?",
				type: "icon"
			},
			{
				key: "writ_issue_min",
				label:
					"What is the minimum number of days after an eviction judgment that a writ can be issued?",
				type: "number"
			},
			{
				key: "writ_exec_min",
				label:
					"What is the minimum number of days after an eviction writ is issued that it can be executed?",
				type: "number"
			},
			{
				key: "pay_after_judg",
				label:
					"Can tenants pay back rent owed to stop evictions for nonpayment after an eviction judgment but before the writ is executed?",
				type: "boolean"
			},
			{
				key: "seal_cases",
				label:
					"Is there a law or court rule that automatically seals some eviction cases?",
				type: "boolean"
			},
			{
				key: "reg_disclosure",
				label:
					"Is there a law or court rule regulating the disclosure of eviction information on credit reports or tenant screening reports?",
				type: "boolean"
			}
		]
	}
];

export const hasPolicySummary = data =>
	policySummarySchema
		.map(s => s.variables)
		.flat()
		.filter(v => data[v.key]).length;

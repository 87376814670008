import React from "react";
import clsx from "clsx";
import {
	Footer as BaseFooter,
	Container
} from "@hyperobjekt/material-ui-website";
import {
	Box,
	Divider,
	Grid,
	List,
	ListItem,
	Typography,
	withStyles
} from "@material-ui/core";
import Logo from "gatsby-theme-hypersite/src/logo";
import { useSiteMetadata } from "gatsby-theme-hypercore";
import { GatsbyLink } from "gatsby-material-ui-components";
import { Link } from "gatsby";
import { FONTS } from "../../theme";

const Footer = ({ classes, className, children, pageContext, ...props }) => {
	const siteMetadata = useSiteMetadata();
	const footerLinks = [...siteMetadata.menuLinks];
	return (
		<BaseFooter className={clsx(classes.root, className)}>
			<Container>
				<Grid container spacing={0}>
					<Grid item xs={12} md={5}>
						<Box>
							<Typography variant="h4">Civil Court Data Initiative</Typography>
						</Box>
						<Box>
							<a href="" target="_blank" rel="noreferrer">
								<img src="/images/logo-lsc-light.svg" alt="" />
							</a>
						</Box>
					</Grid>
					<Grid item xs={12} md={7}>
						<nav>
							<List>
								{siteMetadata.menuLinks.map((link1, i1) => (
									<ListItem key={i1}>
										{link1.subMenu ? (
											<List>
												<ListItem>
													<Typography variant="h6">{link1.name}</Typography>
												</ListItem>
												{link1.subMenu.map((link2, i2) => (
													<ListItem key={i2}>
														<Link to={link2.link}>{link2.name}</Link>
													</ListItem>
												))}
											</List>
										) : null}
									</ListItem>
								))}
							</List>
						</nav>
					</Grid>
				</Grid>
				<Box className={classes.copyright}>
					<Box>
						<Typography>
							{siteMetadata.title} © {new Date().getFullYear()}
						</Typography>
					</Box>
					<Box>
						<Typography>
							Site by{" "}
							<a href="https://hyperobjekt.com" target="_blank">
								Hyperobjekt
							</a>
						</Typography>
					</Box>
					<Box>
						<Typography>
							Artwork by{" "}
							<a href="https://dukeduck.com/" target="_blank">
								Duke & Duck
							</a>
						</Typography>
					</Box>
				</Box>
			</Container>
		</BaseFooter>
	);
};

const styles = theme => ({
	root: {
		position: "relative",
		flexDirection: "column",
		padding: theme.spacing(6, 0),
		background: theme.palette.blue.dark,
		color: theme.palette.text.light,
		"& a": {
			color: theme.palette.text.light,
			textDecoration: "none",
			"&:hover": {
				color: theme.palette.red.main
			}
		},
		"& .MuiGrid-item": {
			"&:first-child": {
				display: "flex",
				flexDirection: "column"
			}
		},
		// Site title
		"& .MuiTypography-h4": {
			fontSize: theme.typography.pxToRem(22),
			"&::after": {
				content: '""',
				width: theme.spacing(3),
				height: theme.spacing(0.25),
				display: "block",
				marginTop: theme.spacing(3),
				marginBottom: theme.spacing(3),
				background: theme.palette.text.light
			}
		},
		// Site logo
		"& img": {
			width: theme.spacing(20)
		},
		// Navigation
		"& nav": {
			marginTop: theme.spacing(1),
			[theme.breakpoints.down("md")]: {
				margin: theme.spacing(0, -4)
			},
			"& > .MuiList-root": {
				display: "flex",
				[theme.breakpoints.down("xs")]: {
					flexWrap: "wrap"
				}
			},
			"& .MuiListItem-root": {
				display: "block",
				[theme.breakpoints.down("xs")]: {
					width: "100%"
				}
			},
			"& > .MuiList-root > .MuiListItem-root": {
				marginBottom: theme.spacing(3),
				"& .MuiListItem-root:first-child::after": {
					content: '""',
					width: theme.spacing(3),
					height: theme.spacing(0.25),
					display: "block",
					marginTop: theme.spacing(3),
					marginBottom: theme.spacing(3),
					background: theme.palette.text.light
				}
			}
		}
	},
	copyright: {
		marginTop: "auto",
		textAlign: "center",
		"& *": {
			fontSize: theme.typography.pxToRem(12)
		},
		[theme.breakpoints.up("sm")]: {
			textAlign: "left"
		}
	}
});

export default withStyles(styles)(Footer);

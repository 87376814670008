import React from "react";
import Hero from "gatsby-theme-hypersite/src/main/hero";
import { Typography, withStyles } from "@material-ui/core";

const JurisdictionHero = ({ classes, children, ...props }) => {
	return (
		<Hero
			{...props}
			classes={classes}
			color="text.light"
			bgcolor="transparent"
			// height="100%"
			// height={550}
			alt=""
		>
			{children}
		</Hero>
	);
};

export default withStyles(theme => ({
	root: {
		position: "relative",
		backgroundColor: theme.palette.blue.dark,
		// paddingTop: 0,
		"& *": {
			color: theme.palette.text.light
		},
		"& h1": {
			// maxWidth: "7em",
			marginBottom: theme.spacing(2)
		},
		"& .MuiBreadcrumbs-root": {
			position: "absolute",
			top: theme.spacing(6),
			left: theme.spacing(6)
		},
		"& a.mapboxgl-ctrl-logo": {
			display: "none"
		}
	},
	container: {
		// maxWidth: "unset",
		marginTop: "auto",
		display: "flex",
		flexDirection: "row-reverse"
	},
	content: {
		width: "50%",
		position: "static",
		flex: 1,
		paddingLeft: theme.spacing(6),
		[theme.breakpoints.down("md")]: {
			paddingLeft: theme.spacing(0)
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(10),
		}
	},
	imageWrapper: {
		width: "50%",
		padding: theme.spacing(0, 2, 0, 0),
		flex: 1,
		"& > div": {
			minHeight: 400
		},
		"& .mapboxgl-map": {
			height: 400
		},
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
		// "& svg *": {
		// 	strokeWidth: 0.01,
		// 	stroke: "white",
		// }
		// width: "100%",
		// height: "100%",
		// position: "absolute",
		// top: 0,
		// left: 0,
		// zIndex: 2,
		// display: "flex",
		// alignItems: "center",
		// justifyContent: "center",
		// "&::before": {
		// 	content: "''",
		// 	width: "100%",
		// 	height: "100%",
		// 	position: "absolute",
		// 	top: 0,
		// 	left: 0,
		// 	backgroundImage: "linear-gradient(90deg, black, transparent)",
		// },
	},
	image: {
		width: "100%"
	}
}))(JurisdictionHero);

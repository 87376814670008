import React from "react";
import clsx from "clsx";
import { GatsbyLink } from "gatsby-material-ui-components";
// import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Grid, withStyles } from "@material-ui/core";

const styles = theme => ({
	logo: {
		"& img": {
			height: 35
		},
		"& path": {
			fill: theme.palette.blue.dark
		}
	},
	light: {
		"& path": {
			fill: theme.palette.blue.dark
		}
	}
});

const Logo = ({ classes, light }) => {
	return (
		<Grid container spacing={3} className={classes.logo}>
			<Grid item>
				<a href="https://www.lsc.gov/" target="_blank">
					<img src="/images/logo-lsc-dark.svg" alt="" />
				</a>
			</Grid>

			<Grid item>
				<GatsbyLink to="/">
					<img src="/images/logo-ccdi-dark.svg" alt="" />

					{/*<svg
						aria-label="Civil Court Data Initiative"
						role="img"
						width="100"
						height="35"
						viewBox="0 0 100 28.6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={clsx("logo", classes.logo, { [classes.light]: light })}>
						<path d="M5.51846 12.064C4.30235 12.064 3.28042 11.7931 2.45265 11.2515C1.62488 10.6997 1.00661 9.96899 0.597833 9.05947C0.199278 8.13972 0 7.12801 0 6.02432C0 4.92062 0.199278 3.91402 0.597833 3.00449C1.00661 2.08475 1.62488 1.35407 2.45265 0.812439C3.28042 0.270813 4.30235 0 5.51846 0C6.86741 0 7.966 0.332129 8.8142 0.996388C9.66241 1.65043 10.1734 2.52929 10.3471 3.63298H8.36966C8.24703 2.99938 7.93023 2.50885 7.41926 2.1614C6.90829 1.80372 6.26447 1.62488 5.4878 1.62488C4.32279 1.62488 3.42349 2.02854 2.78989 2.83587C2.16651 3.63298 1.85481 4.6958 1.85481 6.02432C1.85481 7.36305 2.16651 8.43609 2.78989 9.24342C3.42349 10.0507 4.32279 10.4544 5.4878 10.4544C6.27469 10.4544 6.91851 10.2654 7.41926 9.88724C7.93023 9.4989 8.24703 8.94194 8.36966 8.21637H10.3471C10.1632 9.41204 9.65219 10.3522 8.8142 11.0369C7.97621 11.7216 6.87763 12.064 5.51846 12.064Z"/>
						<path d="M11.9235 11.88V3.34173H13.6097V11.88H11.9235ZM12.7666 2.14607C12.46 2.14607 12.1943 2.04387 11.9695 1.83949C11.7446 1.6351 11.6322 1.37961 11.6322 1.07303C11.6322 0.766453 11.7446 0.510968 11.9695 0.306581C12.1943 0.102194 12.46 0 12.7666 0C13.0834 0 13.3491 0.102194 13.5637 0.306581C13.7885 0.510968 13.9009 0.766453 13.9009 1.07303C13.9009 1.37961 13.7885 1.6351 13.5637 1.83949C13.3491 2.04387 13.0834 2.14607 12.7666 2.14607Z"/>
						<path d="M14.8695 3.34173H16.6937L18.2879 8.00176C18.3697 8.23681 18.431 8.46674 18.4719 8.69157C18.5127 8.9164 18.5434 9.10545 18.5638 9.25875C18.5843 9.41204 18.5996 9.53467 18.6098 9.62664H18.7938C18.804 9.56533 18.8193 9.42736 18.8398 9.21276C18.8602 8.98793 18.8909 8.77844 18.9317 8.58427C18.9726 8.3901 19.0288 8.19593 19.1004 8.00176L20.7252 3.34173H22.5494L19.3763 11.88H18.012L14.8695 3.34173Z"/>
						<path d="M23.8095 11.88V3.34173H25.4957V11.88H23.8095ZM24.6526 2.14607C24.346 2.14607 24.0803 2.04387 23.8555 1.83949C23.6306 1.6351 23.5182 1.37961 23.5182 1.07303C23.5182 0.766453 23.6306 0.510968 23.8555 0.306581C24.0803 0.102194 24.346 0 24.6526 0C24.9694 0 25.2351 0.102194 25.4497 0.306581C25.6745 0.510968 25.7869 0.766453 25.7869 1.07303C25.7869 1.37961 25.6745 1.6351 25.4497 1.83949C25.2351 2.04387 24.9694 2.14607 24.6526 2.14607Z"/>
						<path d="M29.4994 11.88C28.8352 11.88 28.3344 11.7267 27.9972 11.4201C27.66 11.1136 27.4913 10.5975 27.4913 9.87191V0.183949H29.1775V9.45802C29.1775 9.8157 29.2337 10.0712 29.3461 10.2245C29.4586 10.3778 29.6885 10.4544 30.036 10.4544H30.5112V11.88H29.4994Z"/>
						<path d="M41.3561 12.064C40.14 12.064 39.1181 11.7931 38.2903 11.2515C37.4625 10.6997 36.8442 9.96899 36.4355 9.05947C36.0369 8.13972 35.8376 7.12801 35.8376 6.02432C35.8376 4.92062 36.0369 3.91402 36.4355 3.00449C36.8442 2.08475 37.4625 1.35407 38.2903 0.812439C39.1181 0.270813 40.14 0 41.3561 0C42.7051 0 43.8036 0.332129 44.6518 0.996388C45.5 1.65043 46.011 2.52929 46.1847 3.63298H44.2073C44.0847 2.99938 43.7679 2.50885 43.2569 2.1614C42.7459 1.80372 42.1021 1.62488 41.3254 1.62488C40.1604 1.62488 39.2611 2.02854 38.6275 2.83587C38.0041 3.63298 37.6925 4.6958 37.6925 6.02432C37.6925 7.36305 38.0041 8.43609 38.6275 9.24342C39.2611 10.0507 40.1604 10.4544 41.3254 10.4544C42.1123 10.4544 42.7561 10.2654 43.2569 9.88724C43.7679 9.4989 44.0847 8.94194 44.2073 8.21637H46.1847C46.0008 9.41204 45.4898 10.3522 44.6518 11.0369C43.8139 11.7216 42.7153 12.064 41.3561 12.064Z"/>
						<path d="M51.6854 12.0486C50.8576 12.0486 50.1167 11.8596 49.4626 11.4815C48.8188 11.1033 48.313 10.577 47.9451 9.90256C47.5874 9.21787 47.4086 8.4412 47.4086 7.57255C47.4086 6.72434 47.5874 5.96811 47.9451 5.30385C48.313 4.63959 48.8188 4.1184 49.4626 3.74029C50.1167 3.36217 50.8576 3.17311 51.6854 3.17311C52.5131 3.17311 53.2489 3.36217 53.8927 3.74029C54.5468 4.1184 55.0526 4.63959 55.4103 5.30385C55.7782 5.96811 55.9622 6.72434 55.9622 7.57255C55.9622 8.43098 55.7833 9.20254 55.4256 9.88724C55.068 10.5617 54.5621 11.0931 53.9081 11.4815C53.2642 11.8596 52.5233 12.0486 51.6854 12.0486ZM51.6854 10.577C52.1759 10.577 52.6153 10.4493 53.0037 10.1938C53.392 9.93833 53.6935 9.58065 53.9081 9.12078C54.1329 8.66091 54.2453 8.14483 54.2453 7.57255C54.2453 7.0207 54.1329 6.52506 53.9081 6.08563C53.6935 5.63598 53.392 5.28341 53.0037 5.02793C52.6153 4.77244 52.1759 4.6447 51.6854 4.6447C51.1948 4.6447 50.7554 4.77244 50.3671 5.02793C49.9787 5.27319 49.6721 5.62065 49.4473 6.0703C49.2327 6.50974 49.1254 7.01048 49.1254 7.57255C49.1254 8.15505 49.2327 8.67624 49.4473 9.13611C49.6721 9.58576 49.9787 9.93833 50.3671 10.1938C50.7554 10.4493 51.1948 10.577 51.6854 10.577Z"/>
						<path d="M60.569 12.0486C59.312 12.0486 58.4485 11.5837 57.9784 10.6537C57.8149 10.3267 57.7127 9.98432 57.6718 9.62664C57.631 9.26896 57.6105 8.84997 57.6105 8.36966V3.34173H59.2967V8.38499C59.2967 9.25364 59.4449 9.85658 59.7413 10.1938C60.0172 10.48 60.4157 10.623 60.9369 10.623C61.5603 10.623 62.0355 10.4289 62.3625 10.0405C62.5976 9.79526 62.7611 9.46313 62.8531 9.04414C62.9552 8.62514 63.0063 8.05797 63.0063 7.34261V3.34173H64.6925V11.88H63.0063V11.4661C63.0063 11.3128 63.0217 11.1902 63.0523 11.0982C63.083 10.996 63.1341 10.8938 63.2056 10.7917L62.991 10.6384C62.6436 11.1391 62.2808 11.5019 61.9026 11.7267C61.5245 11.9413 61.08 12.0486 60.569 12.0486Z"/>
						<path d="M66.7129 3.34173H68.3991V3.75562C68.3991 3.90891 68.3837 4.03665 68.3531 4.13884C68.3224 4.23082 68.2713 4.3279 68.1998 4.43009L68.4144 4.56806C68.7414 4.06731 69.1042 3.70963 69.5027 3.49502C69.9115 3.28042 70.4123 3.17311 71.005 3.17311H71.1736V4.82865H70.7137C70.029 4.82865 69.4976 5.01771 69.1195 5.39583C68.6392 5.8557 68.3991 6.61193 68.3991 7.66452V11.88H66.7129V3.34173Z"/>
						<path d="M75.1891 11.88C74.5248 11.88 74.0241 11.7267 73.6869 11.4201C73.3496 11.1136 73.181 10.5975 73.181 9.87191V4.752H71.8627V3.34173H73.181V0.183949H74.8672V3.34173H76.6454V4.752H74.8672V9.42736C74.8672 9.78504 74.9183 10.0456 75.0205 10.2091C75.1227 10.3727 75.3577 10.4544 75.7256 10.4544H76.63V11.88H75.1891Z"/>
						<path d="M0.429213 17.4291H3.75562C5.70752 17.4291 7.20465 17.9401 8.24703 18.962C9.2894 19.9737 9.81059 21.4198 9.81059 23.3002C9.81059 25.1703 9.28429 26.6112 8.2317 27.6229C7.18932 28.6244 5.69219 29.1252 3.74029 29.1252H0.429213V17.4291ZM3.72496 27.5003C5.05348 27.5003 6.09585 27.1886 6.85208 26.5652C7.61854 25.9316 8.00176 24.8433 8.00176 23.3002C8.00176 21.8286 7.62365 20.7555 6.86741 20.0811C6.11118 19.3964 5.0637 19.054 3.72496 19.054H2.23804V27.5003H3.72496Z"/>
						<path d="M13.9566 29.2938C13.1186 29.2938 12.4442 29.0741 11.9332 28.6347C11.4222 28.185 11.1667 27.577 11.1667 26.8105C11.1667 26.0236 11.4733 25.4053 12.0865 24.9557C12.5463 24.6185 13.2055 24.3681 14.0639 24.2046L16.8538 23.668C16.8538 23.0958 16.6954 22.641 16.3786 22.3038C16.0414 21.9665 15.5917 21.7979 15.0297 21.7979C14.5698 21.7979 14.1661 21.9308 13.8187 22.1965C13.4814 22.4519 13.2668 22.7994 13.1748 23.2388H11.504C11.647 22.3395 12.0507 21.6446 12.715 21.1541C13.3792 20.6636 14.1559 20.4183 15.045 20.4183C15.5457 20.4183 16.0158 20.4949 16.4553 20.6482C16.9049 20.7913 17.283 21.0059 17.5896 21.292C17.9984 21.6702 18.2539 22.1147 18.3561 22.6257C18.4685 23.1366 18.5247 23.7243 18.5247 24.3885V29.1252H16.8385V28.8033C16.8385 28.5887 16.8998 28.3076 17.0224 27.9602L16.8078 27.8529C16.583 28.2412 16.2917 28.558 15.9341 28.8033C15.464 29.1303 14.8048 29.2938 13.9566 29.2938ZM14.2939 27.8989C14.7435 27.8989 15.1523 27.8069 15.5202 27.6229C15.9392 27.4186 16.2509 27.1375 16.4553 26.7798C16.5677 26.5857 16.6494 26.4068 16.7005 26.2433C16.7516 26.0696 16.8078 25.8397 16.8691 25.5535C16.8998 25.4207 16.9407 25.3236 16.9918 25.2623C17.0429 25.1907 17.1144 25.1499 17.2064 25.1396L17.1604 24.8944L15.3822 25.2623L14.4625 25.4309C13.8698 25.5535 13.4405 25.7375 13.1748 25.9827C12.9602 26.1973 12.8529 26.4579 12.8529 26.7645C12.8529 27.1222 12.9807 27.4032 13.2362 27.6076C13.5019 27.8018 13.8544 27.8989 14.2939 27.8989Z"/>
						<path d="M23.1691 29.1252C22.5049 29.1252 22.0041 28.9719 21.6669 28.6653C21.3297 28.3587 21.161 27.8427 21.161 27.1171V21.9972H19.8427V20.5869H21.161V17.4291H22.8472V20.5869H24.6254V21.9972H22.8472V26.6725C22.8472 27.0302 22.8983 27.2908 23.0005 27.4543C23.1027 27.6178 23.3378 27.6996 23.7057 27.6996H24.6101V29.1252H23.1691Z"/>
						<path d="M28.612 29.2938C27.774 29.2938 27.0996 29.0741 26.5886 28.6347C26.0776 28.185 25.8221 27.577 25.8221 26.8105C25.8221 26.0236 26.1287 25.4053 26.7419 24.9557C27.2018 24.6185 27.8609 24.3681 28.7193 24.2046L31.5092 23.668C31.5092 23.0958 31.3508 22.641 31.034 22.3038C30.6968 21.9665 30.2471 21.7979 29.6851 21.7979C29.2252 21.7979 28.8215 21.9308 28.4741 22.1965C28.1368 22.4519 27.9222 22.7994 27.8302 23.2388H26.1594C26.3025 22.3395 26.7061 21.6446 27.3704 21.1541C28.0346 20.6636 28.8113 20.4183 29.7004 20.4183C30.2011 20.4183 30.6712 20.4949 31.1107 20.6482C31.5603 20.7913 31.9384 21.0059 32.245 21.292C32.6538 21.6702 32.9093 22.1147 33.0115 22.6257C33.1239 23.1366 33.1801 23.7243 33.1801 24.3885V29.1252H31.4939V28.8033C31.4939 28.5887 31.5552 28.3076 31.6778 27.9602L31.4632 27.8529C31.2384 28.2412 30.9472 28.558 30.5895 28.8033C30.1194 29.1303 29.4602 29.2938 28.612 29.2938ZM28.9493 27.8989C29.3989 27.8989 29.8077 27.8069 30.1756 27.6229C30.5946 27.4186 30.9063 27.1375 31.1107 26.7798C31.2231 26.5857 31.3048 26.4068 31.3559 26.2433C31.407 26.0696 31.4632 25.8397 31.5245 25.5535C31.5552 25.4207 31.5961 25.3236 31.6472 25.2623C31.6983 25.1907 31.7698 25.1499 31.8618 25.1396L31.8158 24.8944L30.0376 25.2623L29.1179 25.4309C28.5252 25.5535 28.0959 25.7375 27.8302 25.9827C27.6156 26.1973 27.5083 26.4579 27.5083 26.7645C27.5083 27.1222 27.6361 27.4032 27.8916 27.6076C28.1573 27.8018 28.5098 27.8989 28.9493 27.8989Z"/>
						<path d="M39.4404 29.1252V17.4291H41.2493V29.1252H39.4404Z"/>
						<path d="M43.4049 20.5869H45.0911V21.0008C45.0911 21.1541 45.0758 21.2818 45.0451 21.384C45.0145 21.476 44.9634 21.5731 44.8918 21.6753L45.1064 21.8132C45.4539 21.3125 45.8167 20.9548 46.1948 20.7402C46.5729 20.5256 47.0124 20.4183 47.5131 20.4183C48.7905 20.4183 49.6541 20.8782 50.1037 21.7979C50.2774 22.1351 50.3847 22.4826 50.4256 22.8403C50.4665 23.1877 50.4869 23.6067 50.4869 24.0973V29.1252H48.8007V24.0819C48.8007 23.2133 48.6526 22.6103 48.3562 22.2731C48.0803 21.987 47.6817 21.8439 47.1605 21.8439C46.5372 21.8439 46.0568 22.0381 45.7196 22.4264C45.4948 22.6717 45.3313 23.0038 45.2291 23.4228C45.1371 23.8418 45.0911 24.409 45.0911 25.1243V29.1252H43.4049V20.5869Z"/>
						<path d="M52.4467 29.1252V20.5869H54.1329V29.1252H52.4467ZM53.2898 19.3912C52.9832 19.3912 52.7175 19.2891 52.4926 19.0847C52.2678 18.8803 52.1554 18.6248 52.1554 18.3182C52.1554 18.0116 52.2678 17.7561 52.4926 17.5518C52.7175 17.3474 52.9832 17.2452 53.2898 17.2452C53.6066 17.2452 53.8723 17.3474 54.0869 17.5518C54.3117 17.7561 54.4241 18.0116 54.4241 18.3182C54.4241 18.6248 54.3117 18.8803 54.0869 19.0847C53.8723 19.2891 53.6066 19.3912 53.2898 19.3912Z"/>
						<path d="M58.8571 29.1252C58.1928 29.1252 57.6921 28.9719 57.3548 28.6653C57.0176 28.3587 56.849 27.8427 56.849 27.1171V21.9972H55.5307V20.5869H56.849V17.4291H58.5352V20.5869H60.3133V21.9972H58.5352V26.6725C58.5352 27.0302 58.5863 27.2908 58.6885 27.4543C58.7907 27.6178 59.0257 27.6996 59.3936 27.6996H60.298V29.1252H58.8571Z"/>
						<path d="M61.8626 29.1252V20.5869H63.5488V29.1252H61.8626ZM62.7058 19.3912C62.3992 19.3912 62.1335 19.2891 61.9086 19.0847C61.6838 18.8803 61.5714 18.6248 61.5714 18.3182C61.5714 18.0116 61.6838 17.7561 61.9086 17.5518C62.1335 17.3474 62.3992 17.2452 62.7058 17.2452C63.0226 17.2452 63.2882 17.3474 63.5029 17.5518C63.7277 17.7561 63.8401 18.0116 63.8401 18.3182C63.8401 18.6248 63.7277 18.8803 63.5029 19.0847C63.2882 19.2891 63.0226 19.3912 62.7058 19.3912Z"/>
						<path d="M68.0125 29.2938C67.1745 29.2938 66.5 29.0741 65.989 28.6347C65.4781 28.185 65.2226 27.577 65.2226 26.8105C65.2226 26.0236 65.5292 25.4053 66.1423 24.9557C66.6022 24.6185 67.2613 24.3681 68.1198 24.2046L70.9097 23.668C70.9097 23.0958 70.7513 22.641 70.4345 22.3038C70.0972 21.9665 69.6476 21.7979 69.0855 21.7979C68.6256 21.7979 68.222 21.9308 67.8745 22.1965C67.5373 22.4519 67.3227 22.7994 67.2307 23.2388H65.5598C65.7029 22.3395 66.1066 21.6446 66.7708 21.1541C67.4351 20.6636 68.2117 20.4183 69.1008 20.4183C69.6016 20.4183 70.0717 20.4949 70.5111 20.6482C70.9608 20.7913 71.3389 21.0059 71.6455 21.292C72.0542 21.6702 72.3097 22.1147 72.4119 22.6257C72.5243 23.1366 72.5805 23.7243 72.5805 24.3885V29.1252H70.8943V28.8033C70.8943 28.5887 70.9557 28.3076 71.0783 27.9602L70.8637 27.8529C70.6389 28.2412 70.3476 28.558 69.9899 28.8033C69.5198 29.1303 68.8607 29.2938 68.0125 29.2938ZM68.3497 27.8989C68.7994 27.8989 69.2081 27.8069 69.576 27.6229C69.995 27.4186 70.3067 27.1375 70.5111 26.7798C70.6235 26.5857 70.7053 26.4068 70.7564 26.2433C70.8075 26.0696 70.8637 25.8397 70.925 25.5535C70.9556 25.4207 70.9965 25.3236 71.0476 25.2623C71.0987 25.1907 71.1703 25.1499 71.2622 25.1396L71.2162 24.8944L69.4381 25.2623L68.5183 25.4309C67.9256 25.5535 67.4964 25.7375 67.2307 25.9827C67.0161 26.1973 66.9088 26.4579 66.9088 26.7645C66.9088 27.1222 67.0365 27.4032 67.292 27.6076C67.5577 27.8018 67.9103 27.8989 68.3497 27.8989Z"/>
						<path d="M77.225 29.1252C76.5607 29.1252 76.06 28.9719 75.7227 28.6653C75.3855 28.3587 75.2169 27.8427 75.2169 27.1171V21.9972H73.8986V20.5869H75.2169V17.4291H76.9031V20.5869H78.6812V21.9972H76.9031V26.6725C76.9031 27.0302 76.9542 27.2908 77.0564 27.4543C77.1586 27.6178 77.3936 27.6996 77.7615 27.6996H78.6659V29.1252H77.225Z"/>
						<path d="M80.2306 29.1252V20.5869H81.9168V29.1252H80.2306ZM81.0737 19.3912C80.7671 19.3912 80.5014 19.2891 80.2766 19.0847C80.0517 18.8803 79.9393 18.6248 79.9393 18.3182C79.9393 18.0116 80.0517 17.7561 80.2766 17.5518C80.5014 17.3474 80.7671 17.2452 81.0737 17.2452C81.3905 17.2452 81.6562 17.3474 81.8708 17.5518C82.0956 17.7561 82.208 18.0116 82.208 18.3182C82.208 18.6248 82.0956 18.8803 81.8708 19.0847C81.6562 19.2891 81.3905 19.3912 81.0737 19.3912Z"/>
						<path d="M83.1766 20.5869H85.0008L86.595 25.2469C86.6767 25.482 86.7381 25.7119 86.7789 25.9367C86.8198 26.1616 86.8505 26.3506 86.8709 26.5039C86.8914 26.6572 86.9067 26.7798 86.9169 26.8718H87.1009C87.1111 26.8105 87.1264 26.6725 87.1468 26.4579C87.1673 26.2331 87.1979 26.0236 87.2388 25.8294C87.2797 25.6353 87.3359 25.4411 87.4074 25.2469L89.0323 20.5869H90.8565L87.6834 29.1252H86.3191L83.1766 20.5869Z"/>
						<path d="M95.8305 29.2938C95.0027 29.2938 94.2669 29.1048 93.6231 28.7266C92.9793 28.3383 92.4786 27.812 92.1209 27.1477C91.7632 26.4733 91.5844 25.7221 91.5844 24.8944C91.5844 24.1177 91.7428 23.387 92.0596 22.7023C92.3866 22.0176 92.8669 21.4658 93.5005 21.0468C94.1341 20.6278 94.8954 20.4183 95.7845 20.4183C96.5407 20.4183 97.2357 20.5818 97.8693 20.9088C98.5131 21.2358 99.0292 21.7264 99.4175 22.3804C99.8058 23.0242 100 23.8111 100 24.7411V25.201H94.2363C93.7355 25.201 93.3779 25.1856 93.1632 25.155L93.1019 25.4002C93.1939 25.4207 93.2552 25.4615 93.2859 25.5229C93.3268 25.574 93.3676 25.6557 93.4085 25.7681C93.429 25.8499 93.4749 25.9674 93.5465 26.1207C93.7509 26.6419 94.0574 27.0558 94.4662 27.3624C94.875 27.6689 95.34 27.8222 95.8612 27.8222C96.4028 27.8222 96.8678 27.6843 97.2561 27.4083C97.6444 27.1222 97.9255 26.7594 98.0992 26.32H99.862C99.5759 27.1988 99.0905 27.9142 98.4058 28.466C97.7313 29.0179 96.8729 29.2938 95.8305 29.2938ZM93.3779 24.0973C93.5209 24.0053 93.6538 23.9389 93.7764 23.898C93.899 23.8571 94.037 23.8367 94.1903 23.8367H97.4247C97.5985 23.8367 97.7466 23.8571 97.8693 23.898C98.0021 23.9389 98.1452 24.0053 98.2985 24.0973L98.4518 23.898C98.3598 23.8367 98.2985 23.7856 98.2678 23.7447C98.2372 23.6936 98.2116 23.6272 98.1912 23.5454C98.1707 23.4023 98.1043 23.2286 97.9919 23.0242C97.7875 22.6359 97.4911 22.3395 97.1028 22.1351C96.7247 21.9205 96.3057 21.8132 95.8458 21.8132C95.3655 21.8132 94.9414 21.9205 94.5735 22.1351C94.2056 22.3395 93.9144 22.6359 93.6998 23.0242C93.6078 23.1775 93.5363 23.3512 93.4852 23.5454C93.4647 23.6272 93.4392 23.6936 93.4085 23.7447C93.3881 23.7958 93.3319 23.8469 93.2399 23.898L93.3779 24.0973Z"/>
				  </svg>*/}
				</GatsbyLink>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(Logo);

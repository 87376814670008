import React from "react";
import clsx from "clsx";
import { default as BaseBlock } from "gatsby-theme-hypersite/src/main/block";
import { withStyles } from "@material-ui/core";

const Block = ({ children, classes, className, ...props }) => {
	return (
		<BaseBlock className={clsx(classes.root, className)} {...props}>
			{children}
		</BaseBlock>
	);
};

export default withStyles(theme => ({
	root: {
		// paddingTop: 0,
		// paddingBottom: 0,
		// marginTop: theme.spacing(8),
		// marginBottom: theme.spacing(8),
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	}
}))(Block);

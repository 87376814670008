import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
	Box,
	FormControlLabel,
	Grid,
	Switch,
	Typography,
	useTheme,
	withStyles
} from "@material-ui/core";
import { Scale } from "@hyperobjekt/scales";
import "@hyperobjekt/scales/dist/style.css";
import { JurisdictionSelector, Toggle } from "../../common";

const FILINGS_RATE_KEY = "filings_rate";
const FILINGS_LATEST_KEY = "filings_latest";
const FILINGS_RATE_LABEL = "Since 3/2020";
const FILINGS_LATEST_LABEL = "Latest Month";
const DEFAULT_KEY = FILINGS_RATE_KEY;
// const FILINGS_KEYS_OPTIONS = [
// 	{
// 		key: FILINGS_RATE_KEY,
// 		label: FILINGS_RATE_LABEL
// 	},
// 	{
// 		key: FILINGS_LATEST_KEY,
// 		label: FILINGS_LATEST_LABEL
// 	},
// ];
const SERVICE_TOGGLE_LABEL = "Overlay LSC service areas";

const MapToolbar = ({
	settings,
	setSettings,
	showStateSelect = false,
	showKeyToggle = false,
	showOverlayToggle = false,
	selectOptions,
	latestMonth,
	classes,
	className,
	...props
}) => {
	// const datePandemic = new Date("March 01, 2020").toUTCString();
	// const dateLatest = new Date(new Date(latestMonth).setDate(1)).toUTCString()

	const onToggleChange = (e, val, variable) => {
		setSettings({
			...settings,
			[variable]: val
		});
	};

	const onSwitch = e => {
		setSettings({
			...settings,
			serviceAreas: e.target.checked
		});
	};

	return (
		<Box className={clsx(classes.root, className)} {...props}>
			<Grid
				container
				spacing={2}
				alignItems={showStateSelect ? "center" : "flex-start"}
				justifyContent={showStateSelect ? "center" : "flex-start"}
			>
				{/*{showKeyToggle ?
					<Grid item>
						<Toggle
							variable="filingsKey"
							value={settings.filingsKey}
							options={FILINGS_KEYS_OPTIONS}
							onChange={(e,val) => onToggleChange(e, val, "filingsKey")} />
					</Grid>
				: null}*/}

				{showOverlayToggle ? (
					<Grid item>
						<FormControlLabel
							value="service"
							label={SERVICE_TOGGLE_LABEL}
							labelPlacement="start"
							control={<Switch color="primary" onChange={onSwitch} />}
						/>
					</Grid>
				) : null}
			</Grid>
		</Box>
	);
};

MapToolbar.defaultProps = {};
MapToolbar.propTypes = {};

export default withStyles(theme => ({
	root: {
		width: "100%",
		marginBottom: theme.spacing(2),
		"& .MuiFormControlLabel-root": {
			height: "100%"
		},
		"& .MuiFormControlLabel-root, & .MuiFormControlLabel-labelPlacementStart": {
			marginLeft: 0,
			marginRight: 0,
			"& *": {
				fontSize: theme.typography.pxToRem(12)
			}
		}
	}
}))(MapToolbar);

import React, { useRef, useState, useEffect } from "react";
import {
	Box,
	// Fade,
	Button,
	Typography,
	Grid,
	withStyles
} from "@material-ui/core";
import clsx from "clsx";
import * as d3 from "d3";
import Papa from "papaparse";
import Block from "gatsby-theme-hypersite/block";
import { downloadData, formatDate, roundNumber, slugify } from "../../utils";
import { Modal } from "../common";
import ChartToolbar from "./components/toolbar";
import ChartTooltip from "./components/tooltip";

const DEFAULT_X_KEY = "date";
const DEFAULT_Y_KEY = "filings_count";

const MONTHLY_DATE_FORMAT = "%b ’%y";
const WEEKLY_DATE_FORMAT = "%b %d ’%y";

const DEFAULT_INTERVAL = "monthly";
const DEFAULT_VARIABLE = "filings";
const DEFAULT_UNIT = "count";
const DEFAULT_SHOW_AVG = false;

const MARGIN = {
	top: 30,
	right: 0,
	bottom: 70,
	left: 47
};

const STROKE_WIDTH = 1.5;
const CIRCLE_R = 3;
const CIRCLE_R_HOVER = 5;

const Chart = ({
	classes,
	className,
	level,
	name,
	data,
	avgData,
	...props
}) => {
	const [settings, setSettings] = useState({
		interval: DEFAULT_INTERVAL,
		variable: DEFAULT_VARIABLE,
		unit: DEFAULT_UNIT,
		showAvg: DEFAULT_SHOW_AVG
	});
	const [minDate, setMinDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState(new Date());
	const [xKey, setXKey] = useState(DEFAULT_X_KEY);
	const [yKey, setYKey] = useState(DEFAULT_Y_KEY);
	const [svgWidth, setSvgWidth] = useState(null);
	const [svgHeight, setSvgHeight] = useState(null);
	const [windowWidth, setWindowWidth] = useState(null);
	const [windowHeight, setWindowHeight] = useState(null);
	const [filteredData, setFilteredData] = useState({ main: [], avg: [] });
	const [noData, setNoData] = useState(false);
	const [canShowAvg, setCanShowData] = useState(false);
	const [hoveredData, setHoveredBar] = useState(null);
	const chartRef = useRef(null);
	const svgRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setSvgWidth(chartRef.current.offsetWidth - MARGIN.left - MARGIN.right);
		setSvgHeight(400 - MARGIN.top - MARGIN.bottom);
	}, [chartRef, windowWidth]);

	useEffect(() => {
		let newYKey = `${settings.variable}_${settings.unit}`;
		newYKey =
			newYKey == "filings_comparison" ? "percent_of_historical_average" : newYKey;
		setYKey(newYKey);
	}, [settings]);

	useEffect(() => {
		if (!settings.interval) return;
		const sortedDates = data[settings.interval]
			.filter(d => d.hasOwnProperty(yKey) && d[yKey] !== "")
			.filter(d => d.hasOwnProperty("date"))
			.map(d => new Date(d.date))
			.sort((a, b) => a - b);

		const firstObj = sortedDates && sortedDates[0];
		const lastObj = sortedDates && sortedDates.slice(-1)[0];

		const newMinDate = firstObj ? new Date(firstObj) : new Date();
		const newMaxDate = lastObj ? new Date(lastObj) : new Date();

		setMinDate(newMinDate);
		setMaxDate(newMaxDate);

		setSettings({
			...settings,
			startDate: newMinDate,
			endDate: newMaxDate
		});
	}, [settings.interval, settings.variable, settings.unit, yKey]);

	useEffect(() => {
		// Filter and sort data to settings
		const filterData = d =>
			d
				? d
						.filter(
							d =>
								new Date(d[xKey]).getTime() >=
									new Date(settings.startDate).getTime() &&
								new Date(d[xKey]).getTime() <=
									new Date(settings.endDate).getTime()
						)
						.filter(
							d => d.hasOwnProperty(yKey) && d[yKey] !== "" && !isNaN(d[yKey])
						)
						.sort((a, b) => new Date(a.date) - new Date(b.date))
				: [];

		const newFilteredData = filterData(data[settings.interval]);
		const newAvgFilteredData =
			canShowAvg && avgData ? filterData(avgData[settings.interval]) : [];

		setFilteredData({
			main: newFilteredData,
			avg: newAvgFilteredData
		});

		setNoData(newFilteredData.length === 0);
		setCanShowData(level === "county" && settings.unit === "comparison");
	}, [settings, xKey, yKey]);

	useEffect(() => {
		if (!filteredData.main || !xKey || !yKey || !svgWidth || !svgHeight) return;

		const svg = d3.select(chartRef.current).select("svg");
		svg.selectAll("*").remove();

		const combinedData = [
			...filteredData.main,
			...(settings.showAvg ? filteredData.avg : [])
		];

		const maxY = roundNumber(
			Math.max(
				...combinedData.map(d =>
					d[yKey] && isNaN(d[yKey]) ? 0 : Number(d[yKey])
				)
			)
		);

		const xValues = combinedData.map(d => d[xKey]).sort((a, b) => a - b);

		const yValues = combinedData
			.map(d => Number(d[yKey]))
			.sort((a, b) => a - b);

		const xScale = d3
			.scaleBand()
			.domain(xValues)
			.range([0, svgWidth])
			.padding(0.2);

		const yScale = d3.scaleLinear().domain([0, maxY]).range([svgHeight, 0]);

		const g = svg
			.attr("width", svgWidth + MARGIN.left + MARGIN.right)
			.attr("height", svgHeight + MARGIN.top + MARGIN.bottom)
			.append("g")
			.attr("transform", "translate(" + MARGIN.left + "," + MARGIN.top + ")");

		g.append("g")
			.attr("class", "bars")
			.selectAll("g")
			.data(filteredData.main)
			.enter()
			.append("rect")
			.attr("x", d => xScale(d[xKey]))
			.attr("y", d => yScale(Number(d[yKey])))
			.attr("width", xScale.bandwidth())
			.attr(
				"height",
				d => svgHeight - (yScale(d[yKey]) !== 0 ? yScale(d[yKey]) : 10)
			)
			.on("mousemove", onDataHover)
			.on("mouseleave", onDataLeave);

		g.append("g")
			.attr("transform", "translate(0," + svgHeight + ")")
			.call(
				d3
					.axisBottom(xScale)
					.ticks(4)
					.tickFormat((d, i, ticks) => {
						if (ticks.length > 10 && i % Math.ceil(ticks.length / 10) !== 0)
							return;
						return d3.timeFormat(
							settings.interval === "monthly"
								? MONTHLY_DATE_FORMAT
								: WEEKLY_DATE_FORMAT
						)(new Date(d));
					})
			)
			.selectAll("text")
			.attr("transform", "translate(-10,0)rotate(-45)")
			.style("text-anchor", "end");

		g.append("g").call(d3.axisLeft(yScale));

		if (canShowAvg && filteredData.avg) {
			const avgG = g.append("g").attr("class", "avg");

			avgG
				.append("g")
				.attr("class", "avg-path")
				.append("path")
				.datum(filteredData.avg)
				.attr("fill", "none")
				.attr("stroke-width", STROKE_WIDTH)
				.attr("stroke-opacity", settings.showAvg ? 1 : 0)
				.attr(
					"d",
					d3
						.line()
						.x(d => xScale(d[xKey]) + xScale.bandwidth() / 2)
						.y(d => yScale(d[yKey]))
				);

			avgG
				.append("g")
				.attr("class", "avg-circle")
				.selectAll("circle")
				.data(filteredData.avg)
				.enter()
				.append("circle")
				.attr("r", CIRCLE_R)
				.attr("opacity", settings.showAvg ? 1 : 0)
				.attr("cx", d => xScale(d[xKey]) + xScale.bandwidth() / 2)
				.attr("cy", d => yScale(d[yKey]))
				.on("mousemove", onDataHover)
				.on("mouseleave", onDataLeave);
		}
	}, [filteredData, xKey, yKey, svgWidth, svgHeight]);

	const onDataHover = (e, props) => {
		setHoveredBar({
			...props,
			x: e.offsetX,
			y: e.offsetY
		});
	};

	const onDataLeave = () => {
		setHoveredBar(null);
	};

	const onClickExport = currData => {
		const { main, avg } = currData;
		const exportData = main.map(d => {
			return {
				[`${settings.interval.replace("ly", "")}_start_date`]: d.date,
				jurisdiction: name,
				filings_count: d.filings_count,
				percent_of_historical_average: d.percent_of_historical_average,
				defaults_count: d.defaults_count,
				defaults_comparison: d.defaults_comparison
				// ...natAvgs
			};
		});
		const accessDate = formatDate(new Date(), {
			year: "numeric",
			month: "numeric",
			day: "numeric"
		}).replaceAll("/", "-");
		const csvFilename = `${slugify(name)}-${accessDate}`;
		const csvData = Papa.unparse(exportData);
		downloadData(csvData, csvFilename, "csv");
	};

	return (
		<Block className={clsx(classes.root, className)}>
			<ChartToolbar
				level={level}
				settings={settings}
				setSettings={setSettings}
				minDate={minDate}
				maxDate={maxDate}
			/>

			<Box ref={chartRef} mb={4}>
				<svg ref={svgRef} />

				{hoveredData ? (
					<ChartTooltip
						data={hoveredData}
						value={hoveredData[yKey]}
						settings={settings}
					/>
				) : (
					""
				)}

				{noData ? (
					<Modal open={noData} className={classes.modal}>
						<Typography align='center'>
							No <u>{settings.interval}</u>{" "}
							<u>{settings.variable && settings.variable.slice(0, -1)}</u>{" "}
							<u>{settings.unit}s</u> found for this jurisdiction
						</Typography>
						
						{settings.interval === 'weekly' && settings.unit === 'comparison' ?
							<Typography align='center'>
								Historical comparisons are only available for monthly data
							</Typography>
						: null}
					</Modal>
				) : (
					""
				)}
			</Box>

			<Grid container justifyContent="flex-end">
				<Grid item>
					<Button
						variant="outlined"
						onClick={() => onClickExport(filteredData)}
					>
						Export CSV of selected data
					</Button>
				</Grid>
			</Grid>
		</Block>
	);
};

Chart.defaultProps = {};
Chart.propTypes = {};

export default withStyles(theme => ({
	root: {
		// paddingTop: theme.spacing(6, 0),
		"& .bars rect": {
			fill: theme.palette.blue.med,
			transition: theme.transitions.create(["fill"]),
			"&:hover": {
				fill: theme.palette.blue.dark,
				cursor: "pointer"
			}
		},
		"& .avg-circle circle": {
			fill: theme.palette.red.main,
			cursor: "pointer",
			transition: theme.transitions.create(["fill", "r"]),
			"&:hover": {
				r: CIRCLE_R_HOVER,
				fill: theme.palette.red.dark,
				cursor: "pointer"
			}
		},
		"& .avg-path path": {
			stroke: theme.palette.red.main
		},
		"& > .MuiBox-root > .MuiBox-root": {
			position: "relative"
		},
		"& u": {
			textDecoration: "none",
			borderBottomStyle: "dotted",
			borderBottomWidth: 1
		}
	},
	modal: {
		"& .MuiGrid-item": {
			marginLeft: MARGIN.left,
			marginBottom: MARGIN.bottom
		}
	}
}))(Chart);

import React from "react";
import clsx from "clsx";
import { Box, withStyles } from "@material-ui/core";

const Tooltip = ({ header, classes, className, children, ...props }) => {
	return (
		<Box {...props} className={clsx(classes.root, className)}>
			{header ? <Box className={classes.header}>{header}</Box> : ""}
			{children}
		</Box>
	);
};

Tooltip.propTypes = {};

export default withStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		borderRadius: theme.shape.tooltipRadius,
		background: theme.palette.blue.dark,
		color: theme.palette.text.light,
		transition: "opacity 250ms ease-in-out"
	},
	header: {
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.blue.light}`,
		fontWeight: theme.typography.body.semibold.fontWeight
	}
}))(Tooltip);

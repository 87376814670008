const downloadData = (data, filename, type) => {
	const blob = new Blob([data], { type: `text/${type}` });
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.setAttribute("href", url);
	a.setAttribute("download", `${filename}.${type}`);
	a.click();
	a.remove();
};

const formatDate = (date, options) => {
	let dateObj;
	switch (typeof date) {
		case "string":
			// by casting to a date here, it localizes to a different time zone and it changes dates that in isoformat for the first date of the month to the end of the previous month. for example (February 1, 2020 becomes January 30, 2020). for parity with the subsequent case statemenet, we need this to return dates in the same format.
			// so we will test if the date string has a time component, if not, we add a time component set to 12 PM noon so that even when adjusted it will stay on the same date
			dateObj = /:/.test(date) ? new Date(date) : new Date(date + ' 12:00:00');
			break;
		case "object":
			dateObj = date;
			break;
		default:
			return;
	}
	const output_date = dateObj.toLocaleString(
		"en-US",
		options
			? options
			: {
					year: "numeric",
					month: "long",
					day: "numeric"
			  }
	);

	return output_date
};

const formatDates = (dates, options) => {
	const arr = dates
		.split("\\n")
		.map(r => r.split(",").map(d => formatDate(d, options)));
	const listFormat = new Intl.ListFormat("en");
	return listFormat
		.formatToParts(arr.map(r => r.join(" to ")))
		.map(p => (p.type === "literal" ? p.value.replace(/,/g, ";") : p.value))
		.join("");
};

const formatNumber = (int, decimal) => {
	return int && !isNaN(int)
		? Number(
				decimal !== null ? Number(int).toFixed(decimal) : int
		  ).toLocaleString("en-US")
		: int;
};

const getTimeSince = time => {
	const formats = [
		[60, "seconds", 1], // 60
		[120, "1 minute ago", "1 minute from now"], // 60*2
		[3600, "minutes", 60], // 60*60, 60
		[7200, "1 hour ago", "1 hour from now"], // 60*60*2
		[86400, "hours", 3600], // 60*60*24, 60*60
		[172800, "Yesterday", "Tomorrow"], // 60*60*24*2
		[604800, "days", 86400], // 60*60*24*7, 60*60*24
		[1209600, "Last week", "Next week"], // 60*60*24*7*4*2
		[2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
		[4838400, "Last month", "Next month"], // 60*60*24*7*4*2
		[29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
		[58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
		[2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
		[5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
		[58060800000, "centuries", 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
	];
	let seconds = (+new Date() - time) / 1000;
	let token = "ago";
	let choice = 1;

	if (seconds == 0) {
		return "Just now";
	}

	if (seconds < 0) {
		seconds = Math.abs(seconds);
		token = "from now";
		choice = 2;
	}

	let i = 0;
	let format;
	while ((format = formats[i++]))
		if (seconds < format[0]) {
			if (typeof format[2] == "string") return format[choice];
			else return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
		}
	return time;
};

const getUrl = (...arr) => {
	const slugs = arr
		.filter(s => typeof s === "string" && s.length !== 0)
		.map(s => slugify(s));
	const url = `/${slugs.join("/")}`;
	return url;
};

const roundNumber = number => {
	if (number > 100000) {
		return Math.ceil((number + 1) / 10000) * 10000;
	} else if (number > 10000) {
		return Math.ceil((number + 1) / 1000) * 1000;
	} else if (number > 1000) {
		return Math.ceil((number + 1) / 100) * 100;
	} else if (number > 100) {
		return Math.ceil((number + 1) / 50) * 50;
	} else if (number > 10) {
		return Math.ceil((number + 1) / 10) * 10;
	} else if (number > 10) {
		return Math.ceil((number + 1) / 10) * 10;
	} else if (number > 1) {
		return Math.ceil((number + 1) / 1) * 1;
	}
	return number + 1;
};

const slugify = text => {
	return text
		? text
				.toString()
				.toLowerCase()
				.replace(/\s+/g, "-") // Replace spaces with -
				.replace(/[^\w-]+/g, "") // Remove all non-word chars
				.replace(/--+/g, "-") // Replace multiple - with single -
				.replace(/^-+/, "") // Trim - from start of text
				.replace(/-+$/, "") // Trim - from end of text
		: "";
};

module.exports = {
	downloadData,
	formatDate,
	formatDates,
	formatNumber,
	getTimeSince,
	getUrl,
	roundNumber,
	slugify
};

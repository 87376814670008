module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-hypercore/src/templates/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"quality":80,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"commonmark":true},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-hypercore/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","header":{"displaySiteLogo":false},"templates":{"default":"/opt/build/repo/src/templates/page.js","page":"/opt/build/repo/src/templates/page.js","report":"/opt/build/repo/src/templates/report.js","contact":"/opt/build/repo/src/templates/contact.js","home":"/opt/build/repo/src/templates/home.js","data":"/opt/build/repo/src/templates/data.js","releases":"/opt/build/repo/src/templates/releases.js","research":"/opt/build/repo/src/templates/research.js","team":"/opt/build/repo/src/templates/team.js","tracker":"/opt/build/repo/src/templates/tracker.js"}},
    },{
      plugin: require('../node_modules/gatsby-theme-hypersite/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","header":{"displaySiteLogo":false},"templates":{"default":"/opt/build/repo/src/templates/page.js","page":"/opt/build/repo/src/templates/page.js","report":"/opt/build/repo/src/templates/report.js","contact":"/opt/build/repo/src/templates/contact.js","home":"/opt/build/repo/src/templates/home.js","data":"/opt/build/repo/src/templates/data.js","releases":"/opt/build/repo/src/templates/releases.js","research":"/opt/build/repo/src/templates/research.js","team":"/opt/build/repo/src/templates/team.js","tracker":"/opt/build/repo/src/templates/tracker.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
